import styled from "styled-components";

// Figma Text Types
export const Heading1 = styled.section`
  /* GM/Heading/Heading 1 */
  font-family: Inter;
  font-size: clamp(3.2rem, 6.2vw, 6rem);
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "clig" off, "liga" off;

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: ${(props) => props.lineHeight || "120%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "1"};
  margin-top: ${(props) => props.marginTop || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
`;

export const Heading2 = styled.section`
  /* GM/Heading/Heading 2 */
  font-family: ${(props) => props.fontFamily || "Inter"};
  font-size: clamp(2.5rem, 5vw, ${(props) => props.maxFontSize || "5rem"});
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "400"};
  font-feature-settings: "clig" off, "liga" off;

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: ${(props) => props.lineHeight || "100%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-top: ${(props) => props.marginTop || "0px"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};

  @media (max-width: 560px) {
    text-align: ${(props) => props.textAlignSub || props.textAlign || "left"};
  }
`;

export const Heading3 = styled.section`
  /* GM/Heading/Heading 3 */
  font-family: Inter;
  font-size: clamp(
    ${(props) => props.minFontSize || "1.875rem"},
    3.75vw,
    ${(props) => props.maxFontSize || "3.75rem"}
  );
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "clig" off, "liga" off;

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: ${(props) => props.lineHeight || "140%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};

  // MAKE A MINFONTSIZE
`;

export const Heading4 = styled.section`
  /* GM/Heading/Heading 4 */
  font-family: Inter;
  font-size: clamp(1.5rem, 3.25vw, 3.25rem);
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "clig" off, "liga" off;

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: ${(props) => props.lineHeight || "140%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
`;

export const Heading5 = styled.section`
  /* GM/Heading/Heading 5 */
  font-family: Inter;
  font-size: clamp(1.5rem, 2vw, ${(props) => props.maxFontSize || "2.32rem"});
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "400"};
  font-feature-settings: "clig" off, "liga" off;

  color: ${(props) => props.fontColor || "var(--Lavender, #A6BCFF)"};
  line-height: ${(props) => props.lineHeight || "120%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
`;

export const Heading6 = styled.section`
  /* GM/Heading/Heading 5 */
  font-family: Inter;
  font-size: clamp(1rem, 1.5vw, ${(props) => props.maxFontSize || "2.32rem"});
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "clig" off, "liga" off;

  color: ${(props) => props.fontColor || "var(--Lavender, #A6BCFF)"};
  line-height: ${(props) => props.lineHeight || "120%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};

  // @media (max-width: 960px) {
  //   font-size: clamp(0.6rem, 1.5vw, 0.9rem);
  // }
`;

export const Body1 = styled.section`
  /* GM/Paragraph/Body 1 */
  font-family: Inter;
  font-size: clamp(${(props) => props.minFontSize || "1.2rem"}, 2vw, 1.7rem);
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "400"};

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: ${(props) => props.lineHeight || "140%"};
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  white-space: ${(props) => props.whiteSpace || "normal"};

  @media (max-width: 560px) {
    text-align: ${(props) =>
      props.mobileTextAlign || props.textAlign || "left"};
  }
`;

export const Body2 = styled.section`
  /* GM/Paragraph/Body 2 */
  font-family: Inter;
  font-size: clamp(
    0.8rem,
    1.33vw,
    ${(props) => props.maxFontSize || "1.13rem"}
  );
  font-style: normal;
  font-weight: 400;

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: 140%;
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  white-space: ${(props) => props.whiteSpace || "normal"};

  @media (max-width: 560px) {
    text-align: ${(props) =>
      props.textAlignMobile || props.textAlign || "left"};
  }
`;

export const Body3 = styled.section`
  /* GM/Paragraph/Body 3 */
  font-family: Inter;
  font-size: clamp(0.6rem, 1vw, 0.84rem);
  font-style: normal;
  font-weight: 400;

  color: ${(props) => props.fontColor || "var(--White, #FFF)"};
  line-height: 140%;
  text-align: ${(props) => props.textAlign || "left"};
  max-width: ${(props) => props.maxWidth || "100%"};
  z-index: ${(props) => props.zIndex || "auto"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  white-space: ${(props) => props.whiteSpace || "normal"};

  @media (max-width: 560px) {
    text-align: ${(props) =>
      props.textAlignMobile || props.textAlign || "left"};
  }
`;

export const GreymattersHeaderName = styled.section`
  color: ${(props) => props.fontColor || "var(--white, #fff)"};
  font-family: Valizas;
  font-size: 24px;
  font-style: normal;
  font-weight: 650;
  line-height: normal;

  margin: 0 16px;

  @media (max-width: 1400px) {
    font-size: 20px;
  }

  @media (max-width: 1079px) {
    font-size: 24px;
  }
`;

export const SideBarButtonText = styled.section`
  font-family: Inter;
  font-size: clamp(1.3rem, 1.6vw, 1.45rem);
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || "400"};

  color: ${(props) => props.fontColor || "var(--Black, #000)"};
  line-height: 140%;
  text-align: ${(props) => props.textAlign || "left"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};

  white-space: nowrap;
`;
