import React from "react";
import "style/index.css";

// components
import { Body1, Heading5 } from "components/TextStyles";
import {
  LowerWrapper,
  Tile,
  BackgroundWoman,
} from "screens/organisations/OrganisationStyledSections";

// assets
import GreyMattersEmblem from "assets/svg/gmlogos/GMLogoWhite";
import BackgroundWomanImg from "assets/img/organisations/backgroundWoman.png";

export default function LowerTextSection() {
  return (
    <>
      <LowerWrapper className="container">
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Age is simply a number.
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            We believe age is an asset and life is a gift. Happiness, passion
            and fulfilment have no age limit and neither does our talent pool.
            If you’re looking for the perfect candidate, with the right balance
            of skill set, knowledge and life experience, sign up below to access
            our talent.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Retiring, retired, or never going to retire.
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            This is the most exciting time of your life! It’s time to cultivate
            your purpose, pursue your passions, learn new tricks and tick things
            off your bucket list. Sign up to get access to age inclusive
            employers and a community who value your experience.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Never fear...<br></br>Helping Hands are here!
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Sometimes life gets so busy, we need a ‘Helping Hand’. Someone to do
            something that will free us up to do all the other things we need to
            do. ‘Helping Hands’ is a service designed to do just that. Sign up
            to find your Good Samaritan.
          </Body1>
        </Tile>

        <BackgroundWoman src={BackgroundWomanImg} />
      </LowerWrapper>
    </>
  );
}
