import React from "react";

// components
import "style/index.css";
import { Heading4 } from "components/TextStyles";
import {
  TopTitle,
  TopWrapper,
  Gradient,
  TopImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/clubs/clubs1.png";

export default function Top() {
  return (
    <>
      <TopImage image={image} />
      <Gradient />
      <TopWrapper className="container">
        <TopTitle>Clubs</TopTitle>
        <Heading4>
          Join Grey Matters to become part of the longevity economy. Attract new
          over 45s members to your club as well as retaining and hiring
          outstanding midlife talent.
        </Heading4>
      </TopWrapper>
    </>
  );
}
