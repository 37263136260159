import React, { useState } from "react";
import styled from "styled-components";

// components
import {
  StyledSelect,
  StyledTextField,
  StyledFormControl,
  StyledMenuProps,
  StyledInputLabel,
} from "components/StyledMuiElements";
import { CircularProgress, MenuItem } from "@mui/material";
import { Body1 } from "components/TextStyles";
import Button from "components/styledelements/Button";

export default function ContactUsForm() {
  const initialState = {
    inquiryType: "",
    name: "",
    email: "",
    message: "",
    errors: {},
  };

  const [formState, setFormState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: validateField(name, value),
      },
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "name":
        return value.length > 0 ? "" : "This field is required.";
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
          ? ""
          : "Invalid email format.";
      case "message":
        return value.length >= 5 && value.length <= 10000
          ? ""
          : "Please tell us more!";
      default:
        return "";
    }
  };

  const isFormValid = () => {
    const areFieldsFilled =
      formState.inquiryType &&
      formState.name &&
      formState.email &&
      formState.message;
    const areNoErrors = Object.values(formState.errors).every(
      (error) => error === ""
    );
    return areFieldsFilled && areNoErrors;
  };

  const sendMessage = () => {
    setIsSubmitting(true);

    const formData = {
      type: "ContactUs",
      inquiryType: formState.inquiryType,
      name: formState.name,
      email: formState.email,
      message: formState.message,
    };

    fetch(
      "https://nnfek1uak2.execute-api.us-east-1.amazonaws.com/dev/contact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsSubmittedSuccessfully(true); // Show success message
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSubmitting(false); // End loading
      });
  };

  const renderForm = () => {
    return (
      <>
        <Body1>We appreciate your feedback, suggestions and interest.</Body1>
        <FormWrapper>
          <StyledFormControl fullWidth variant="outlined">
            <StyledInputLabel htmlFor="inquiry-type">
              Inquiry Type
            </StyledInputLabel>
            <StyledSelect
              id="inquiry-type"
              value={formState.inquiryType}
              name="inquiryType"
              onChange={handleChange}
              label="Inquiry Type"
              MenuProps={StyledMenuProps}
            >
              <MenuItem value="general">General Feedback</MenuItem>
              <MenuItem value="partnerships">Partnerships</MenuItem>
              <MenuItem value="press">Press</MenuItem>
              <MenuItem value="bookDemo">Book a Demo</MenuItem>
              <MenuItem value="memberQuery">Member Query</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </StyledSelect>
          </StyledFormControl>
          <StyledTextField
            fullWidth
            name="name"
            onChange={handleChange}
            variant="outlined"
            label="Name"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.name}
            error={!!formState.errors.name}
          />
          <StyledTextField
            fullWidth
            name="email"
            onChange={handleChange}
            variant="outlined"
            label="Email"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.email}
            error={!!formState.errors.email}
          />
          <StyledTextField
            fullWidth
            multiline
            name="message"
            onChange={handleChange}
            rows={4}
            variant="outlined"
            label="Message"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.message}
            error={!!formState.errors.message}
          />
        </FormWrapper>
        <Button
          disabled={!isFormValid()}
          onClick={isFormValid() ? sendMessage : null}
          text="Send"
          arrow="right"
        />
      </>
    );
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress color="inherit" sx={{ color: "white" }} />
      ) : !isSubmittedSuccessfully ? (
        renderForm()
      ) : (
        <Body1>Thank you for your feedback!</Body1>
      )}
    </>
  );
}

export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50vw;
  gap: 4px;

  @media (max-width: 960px) {
    width: 80vw;
  }
  @media (max-width: 760px) {
    width: 100%;
  }
`;
