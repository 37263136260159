import React, { useState } from "react";
import "style/index.css";

// components
import {
  StyledTextField,
  StyledSelect,
  StyledFormControl,
  StyledMenuProps,
  StyledInputLabel,
  Row,
} from "components/StyledMuiElements";
import { Body1 } from "components/TextStyles";
import { CircularProgress, MenuItem } from "@mui/material";
import { FormWrapper } from "screens/organisations/OrganisationStyledSections";
import Button from "components/styledelements/Button";

export default function SignUp() {
  const initialState = {
    fullName: "",
    jobTitle: "",
    companyName: "",
    numberOfEmployees: "",
    phoneNumber: "",
    email: "",
    companyLogo: "",
    errors: {},
  };

  const [formState, setFormState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
      errors: {
        ...prevState.errors,
        [name]: validateField(name, newValue),
      },
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "fullName":
      case "jobTitle":
      case "companyName":
        return value.length > 0 ? "" : "This field is required.";
      case "phoneNumber":
        return /^(\d{10}|\+\d{11})$/.test(value) ? "" : "Invalid phone number.";
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
          ? ""
          : "Invalid email format.";
      // TODO: Validate logo
      default:
        return "";
    }
  };

  const isFormValid = () => {
    const areFieldsFilled =
      formState.fullName &&
      formState.jobTitle &&
      formState.companyName &&
      formState.numberOfEmployees &&
      formState.phoneNumber &&
      formState.email;
    const areNoErrors = Object.values(formState.errors).every(
      (error) => error === ""
    );
    return areFieldsFilled && areNoErrors;
  };

  const sendMessage = () => {
    setIsSubmitting(true);

    const formData = {
      type: "Corporates",
      manifesto: true,
      name: formState.fullName,
      jobTitle: formState.jobTitle,
      companyName: formState.companyName,
      numberOfEmployees: formState.numberOfEmployees,
      phoneNumber: formState.phoneNumber,
      email: formState.email,
      companyLogo: formState.companyLogo,
    };

    fetch("https://nnfek1uak2.execute-api.us-east-1.amazonaws.com/dev/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsSubmittedSuccessfully(true); // Show success message
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSubmitting(false); // End loading
      });
  };

  const renderForm = () => {
    return (
      <>
        <FormWrapper>
          <StyledTextField
            fullWidth
            name="fullName"
            onChange={handleChange}
            variant="outlined"
            label="Full Name"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.fullName}
            error={!!formState.errors.fullName}
          />

          <Row>
            <StyledTextField
              fullWidth
              name="jobTitle"
              onChange={handleChange}
              variant="outlined"
              label="Your Job Title"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.jobTitle}
              error={!!formState.errors.jobTitle}
            />
            <StyledTextField
              fullWidth
              name="companyName"
              onChange={handleChange}
              variant="outlined"
              label="Company Name"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.companyName}
              error={!!formState.errors.companyName}
            />
          </Row>
          <Row>
            <StyledFormControl fullWidth variant="outlined">
              <StyledInputLabel htmlFor="number-of-employees">
                Number of Employees
              </StyledInputLabel>
              <StyledSelect
                id="number-of-employees"
                name="numberOfEmployees"
                value={formState.numberOfEmployees}
                onChange={handleChange}
                label="Number of Employees"
                MenuProps={StyledMenuProps}
              >
                <MenuItem value="5 - 25">5 - 25</MenuItem>
                <MenuItem value="25 - 50">25 - 50</MenuItem>
                <MenuItem value="50 - 100">50 - 100</MenuItem>
                <MenuItem value="100 - 500">100 - 500</MenuItem>
                <MenuItem value="500 - 1250">500 - 1250</MenuItem>
                <MenuItem value="1250 - 4000">1250 - 4000</MenuItem>
                <MenuItem value="4000 - 10000">4000 - 10000</MenuItem>
                <MenuItem value="10000+">10000+</MenuItem>
              </StyledSelect>
            </StyledFormControl>
            <StyledTextField
              fullWidth
              name="phoneNumber"
              onChange={handleChange}
              variant="outlined"
              label="Phone Number"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.phoneNumber}
              error={!!formState.errors.phoneNumber}
            />
          </Row>
          <StyledTextField
            fullWidth
            name="email"
            onChange={handleChange}
            variant="outlined"
            label="Work Email"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.email}
            error={!!formState.errors.email}
          />
          <StyledTextField
            fullWidth
            multiline
            name="companyLogo"
            onChange={handleChange}
            variant="outlined"
            label="Your Company Logo's URL"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.companyLogo}
            error={!!formState.errors.companyLogo}
          />
        </FormWrapper>
        <Button
          disabled={!isFormValid()}
          onClick={isFormValid() ? sendMessage : null}
          text="Sign"
          arrow="right"
        />
      </>
    );
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress
          color="inherit"
          sx={{ color: "white", flexGrow: "0" }}
        />
      ) : !isSubmittedSuccessfully ? (
        renderForm()
      ) : (
        <Body1 textAlign="center">Thank you for signing our Manifesto!</Body1>
      )}
    </>
  );
}
