import React from "react";
import "style/index.css";
import styled from "styled-components";

// components
import { Body1, Heading3 } from "components/TextStyles";
import Button from "components/styledelements/Button";
import GMLogo from "assets/svg/gmlogos/GMLogoSmallWhite";

export default function RepurposeQuiz() {
  return (
    <>
      <Wrapper>
        <GMLogo width="48px" stroke="black" />
        <Heading3 fontColor="black" textAlign="center">
          Start your Journey by taking our<br></br>
          Re&#x200A;&middot;&#x200A;purpose Quiz
        </Heading3>
        <Body1 fontColor="black" textAlign="center" marginBottom="20px">
          Take our 2 minute quiz to discover your archetype and begin your new
          chapter.
        </Body1>
        <Button
          to="/repurpose-quiz"
          text="Take Quiz"
          arrow="right"
          color="black"
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  padding: 72px 15vw;
  background-color: #a6bcff;
  @media (max-width: 560px) {
    padding: 48px 5vw;
  }
`;
