import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  LeftSide,
  RightSide,
  UpperWrapper,
  UpperImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/corporations/corporations2.png";

export default function UpperTextSection() {
  return (
    <>
      <UpperWrapper className="container">
        <LeftSide>
          <Heading3 marginBottom="20px">
            Revolutionise your ageing employee transition plan.
          </Heading3>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Empower your employees to take the future of work (and life) into
            their own hands and build a sustainable future.
          </Body1>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Get ahead of the ageing curve and become an age inclusive employer.
            Be one of the first age inclusive employers.
          </Body1>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Our AI driven platform enables your company to improve its ESG
            performance in relation to one of society's greatest economic
            challenges and opportunities: an ageing population.
          </Body1>
        </LeftSide>
        <RightSide>
          <UpperImage src={image} />
        </RightSide>
      </UpperWrapper>
    </>
  );
}
