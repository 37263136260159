import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  LeftSide,
  RightSide,
  UpperWrapper,
  UpperImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/corporations/corporations2.png";

export default function UpperTextSection() {
  return (
    <>
      <UpperWrapper className="container">
        <LeftSide>
          <Heading3>Crafting Innovative Ageing Policies</Heading3>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Governments have a unique opportunity to lead in the creation of
            sustainable, age inclusive policies. By focusing on the ageing
            demographic, there is potential to revolutionise public services and
            community engagement. This involves rethinking strategies for
            workforce participation, retirement planning, and lifelong learning.
          </Body1>
        </LeftSide>
        <RightSide>
          <UpperImage src={image} />
        </RightSide>
      </UpperWrapper>
    </>
  );
}
