import styled from "styled-components";

export const ExperienceWrapper = styled.section`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExperienceHeaderWrapper = styled.section`
  margin-top: ${(props) => props.marginTop || "0px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 1;
  gap: 20px;
  padding: 0 20vw;

  @media (max-width: 560px) {
    padding: 0 3vw;
  }
`;

export const ExperienceBackgroundImage = styled.section`
  opacity: 0.7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      180deg,
      #12141a 10%,
      rgba(18, 20, 26, 0) 50%,
      #12141a 95%
    ),
    url(${(props) => props.backgroundImage});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  z-index: -2;
  overflow: hidden;
`;

export const ExperienceTilesWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  max-width: 1450px;
  row-gap: 3vh;
  margin: 8vh 0;
`;

export const ExperienceTile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 22.875rem;
  padding: 1.5rem 1.5rem;
  margin: 0 clamp(16px, 3vw, 48px);
  gap: 0.5rem;

  border-radius: 1rem;
  background: rgba(29, 31, 39, 0.6);
  backdrop-filter: blur(50px);
  border: 2px solid ${(props) => props.borderColor || "var(--Aqua, #4BFFDF)"};
`;

export const ExperienceArrowWrapper = styled.section`
  position: absolute;
  right: 12px;
  bottom: 2px;
  z-index: 1000;
`;

export const ExperienceIconWrapper = styled.section`
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1000;
`;
