import React, { useState } from "react";
import "style/index.css";

// components
import { Body1, Body2, Heading3 } from "components/TextStyles";
import {
  SignUpWrapper,
  SignUpLeftSide,
  SignUpRightSide,
} from "screens/organisations/OrganisationStyledSections";
import CorporateManifestoSignUp from "components/forms/manifesto/CorporateManifestoSignUp";
import ClubManifestoSignUp from "components/forms/manifesto/ClubManifestoSignUp";
import PublicSectorManifestoSignUp from "components/forms/manifesto/PublicSectorManifestoSignUp";
import CustomButtonGroup from "components/elements/ButtonGroup";

export default function SignUp() {
  const [formType, setFormType] = useState("Corporate");

  const handleCorporateClick = () => {
    setFormType("Corporate");
  };

  const handleClubClick = () => {
    setFormType("Club");
  };

  const handlePublicSectorClick = () => {
    setFormType("Public Sector");
  };

  const buttons = [
    { name: "Corporate", action: handleCorporateClick },
    { name: "Club", action: handleClubClick },
    { name: "Public Sector", action: handlePublicSectorClick },
  ];

  return (
    <>
      <SignUpWrapper className="container">
        <SignUpLeftSide>
          <Heading3>Sign the Manifesto</Heading3>
          <Body1>
            Please demonstrate your commitment to age inclusivity and the
            longevity economy.
          </Body1>
        </SignUpLeftSide>
        <SignUpRightSide>
          <CustomButtonGroup buttons={buttons} />
          {formType === "Corporate" && <CorporateManifestoSignUp />}
          {formType === "Club" && <ClubManifestoSignUp />}
          {formType === "Public Sector" && <PublicSectorManifestoSignUp />}
          <Body2 zIndex="1">
            Disclaimer: By giving us your logo URL, you are giving us permission
            to display your logo on our website as a supporter of our Manifesto
          </Body2>
        </SignUpRightSide>
      </SignUpWrapper>
    </>
  );
}
