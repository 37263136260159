import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  SignUpWrapper,
  SignUpLeftSide,
  SignUpRightSide,
} from "screens/organisations/OrganisationStyledSections";
import PublicSectorManifestoSignUp from "components/forms/manifesto/PublicSectorManifestoSignUp";

export default function SignUp() {
  return (
    <>
      <SignUpWrapper className="container">
        <SignUpLeftSide>
          <Heading3>
            <span style={{ color: "var(--Lavender, #A6BCFF)" }}>
              Public Sector
            </span>
          </Heading3>
          <Body1>
            We would love to partner with you to build a more inclusive society!
          </Body1>
        </SignUpLeftSide>
        <SignUpRightSide>
          <PublicSectorManifestoSignUp />
        </SignUpRightSide>
      </SignUpWrapper>
    </>
  );
}
