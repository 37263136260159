import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Top from "screens/about/sections/Top";
import OurMission from "./sections/OurMission";
import AboutUs from "./sections/AboutUs";
import Values from "./sections/Values";
import LeaderShipTeam from "./sections/LeaderShipTeam";
// import OpenPositions from "./About/Sections/OpenPositions";
// import Video from "./About/Sections/Video";
import JoinBeta from "../../components/common/RegisterNow";
import Footer from "components/common/Footer";

export default function About() {
  return (
    <>
      <TopNavbar />
      <Top />
      <OurMission />
      <AboutUs />
      {/* <Video /> */}
      <LeaderShipTeam />
      <Values />
      {/* <OpenPositions /> */}
      <JoinBeta />
      <Footer />
    </>
  );
}
