import styled from "styled-components";

export const AirQuotesWrapper = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0 4vw;

  & > *:first-child {
    align-self: flex-start; // Top vertically aligned
    justify-contents: flex-end;
  }

  & > *:nth-child(2) {
    align-self: center; // Center vertically aligned
  }

  & > *:last-child {
    align-self: flex-end; // Bottom vertically aligned
    justify-contents: flex-start;
  }

  & > *:not(:last-child) {
    padding-right: 10px; // Adds padding to every child except the last one
  }
`;
