import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Top from "./sections/Top";
import JoinBeta from "components/common/RegisterNow";
import Footer from "components/common/Footer";
import ArticlesContainer from "./sections/Media";

export default function Research() {
  return (
    <>
      <TopNavbar />
      <Top />
      <ArticlesContainer />

      <JoinBeta />
      <Footer />
    </>
  );
}
