import React from "react";
import "style/index.css";

// components
import { Heading4 } from "components/TextStyles";
import {
  TopTitle,
  TopWrapper,
  Gradient,
  TopImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/corporations/corporations1.png";

export default function Top() {
  return (
    <>
      <TopImage image={image} />
      <Gradient />
      <TopWrapper className="container">
        <TopTitle>Corporations</TopTitle>
        <Heading4>
          WEF's longevity economy initiative has been rethinking retirement to
          support individuals to be resilient in their longer lives. How are you
          solving your ageing demographic challenge?
          {/* Ageing population. Crisis or opportunity? */}
          {/* The World Economic Forum cites the ageing population as a global Top
          10 Risk. We see it as the biggest opportunity. Has your company got a
          longevity strategy? */}
        </Heading4>
      </TopWrapper>
    </>
  );
}
