import styled from "styled-components";

// Top
export const TopWrapper = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  gap: 32px;
  padding-top: 64px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  order: 2;

  @media (max-width: 960px) {
    min-height: 60vh;
  }

  .left {
    width: 87%;
    @media (max-width: 960px) {
      width: 100%;
    }
  }
`;

export const TopTitle = styled.section`
  z-index: 1;
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;

  /* GM/Heading/Heading 1 */
  font-family: Inter;
  font-size: clamp(64px, 7vw, 96px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 96px */
  width: 70vw;

  @media (max-width: 960px) {
    font-size: clamp(48px, 6vw, 64px);
  }

  @media (max-width: 560px) {
    font-size: clamp(40px, 5vw, 54px);
    margin-top: 80px;
  }
`;

export const Gradient = styled.section`
  position: absolute;
  width: 100%;
  height: 60vh;

  overflow: hidden;
  margin-top: 41vh;

  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(18, 20, 26, 1) 100%
  );
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    height: 36vh;
    margin-top: 25vh;
  }
`;

export const TopImage = styled.section`
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 0; /* Ensure it's behind other content */

  overflow: hidden;
  background-image: url(${(props) => props.image});

  background-color: lightgray;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 960px) {
    height: 60vh;
  }
`;

// Upper
export const UpperWrapper = styled.section`
  display: flex;
  flex-direction: row;

  width: 100%;
  padding-top: calc(96px + 3vw);

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const LeftSide = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: 32px;

  width: 60%;

  @media (max-width: 760px) {
    width: 85%;
  }

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const RightSide = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 40%;

  @media (max-width: 760px) {
    width: 100%;
    align-items: center;
    padding-top: 48px;
  }
`;

export const UpperImage = styled.img`
  border-radius: 16px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  /* DS */
  box-shadow: 53px 67px 106px 0px rgba(0, 0, 0, 0.55);

  width: 25vw;

  @media (max-width: 760px) {
    width: 45vw;
  }

  @media (max-width: 560px) {
    width: 65vw;
  }

  @media (min-width: 1800px) {
    width: 20vw;
  }

  @media (min-width: 2200px) {
    width: 15vw;
  }
`;

// Lower
export const LowerWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 48px;

  width: 100%;
  padding-top: ${(props) => props.paddingTop || "calc(96px + 3vw)"};
  padding-bottom: ${(props) => props.paddingBottom || "calc(96px + 3vw)"};

  @media (max-width: 760px) {
    flex-direction: column;
    align-items: center;

    padding-top: calc(64px + 3vw);
    padding-bottom: calc(64px + 3vw);
  }
`;

export const Tile = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  flex: 1;
`;

export const BackgroundWoman = styled.img`
  position: absolute;
  bottom: 0px;

  right: auto;
  transform: scale(0.95);
  transform-origin: bottom;
  max-width: 90%;
  height: auto;
  z-index: -1;

  opacity: 0.25;

  @media (max-width: 760px) {
    display: none;
  }

  @media (min-width: 960px) {
    transform: scale(0.75);
  }

  @media (min-width: 1300px) {
    transform: scale(0.6);
  }
  @media (min-width: 1920px) {
    transform: scale(0.5);
  }
`;

// SignUp
export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SignUpWrapper = styled.section`
  display: flex;
  flex-direction: row;
  background-color: #12141a;

  @media (max-width: 1080px) {
    flex-direction: column;
  }

  padding-top: 64px;
  padding-bottom: 64px;
  gap: 64px;
`;

export const SignUpLeftSide = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 32px;
  width: 100%;
`;

export const SignUpRightSide = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  width: 100%;

  > * {
    flex-grow: 0;
  }
`;
