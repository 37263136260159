import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import Button from "components/styledelements/Button";

export default function RegisterNow() {
  return (
    <>
      <Wrapper className="container flexSpaceCenter">
        <Text>The time is now to join the midlife revolution.</Text>
        <Button to="/sign-in" color="black" arrow="right" text="Register" />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  padding: 7vh 10vw;
  align-items: center;
  gap: 2rem;

  background: var(--Aqua, #4bffdf);

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 5vh 5vw;
  }
`;

const Text = styled.div`
  color: var(--Slate, #12141a);
  font-feature-settings: "clig" off, "liga" off;

  /* GM/Heading/Heading 2 */
  font-family: Inter;
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 4rem */

  width: 55vw;
  flex-shrink: 0;

  @media (max-width: 760px) {
    width: 100%;
  }
`;
