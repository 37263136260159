import React, { useState } from "react";

// components
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { styled } from "@mui/material/styles";
import { Body2 } from "components/TextStyles";

const CustomButtonGroup = ({ buttons }) => {
  const [selectedButton, setSelectedButton] = useState(0);

  const handleClick = (action, index) => {
    setSelectedButton(index);
    action();
  };

  return (
    <CustomButtonGroupWrapper variant="contained" fullWidth disableElevation>
      {buttons.map((button, index) => (
        <EqualWidthButton
          disableRipple
          key={index}
          variant="contained"
          disableElevation
          selected={selectedButton === index}
          onClick={() => handleClick(button.action, index)}
        >
          <Body2 fontColor={selectedButton === index ? "black" : "white"}>
            {button.name}
          </Body2>
        </EqualWidthButton>
      ))}
    </CustomButtonGroupWrapper>
  );
};

const CustomButtonGroupWrapper = styled(ButtonGroup)(() => ({
  display: "flex",
  backgroundColor: "transparent",
  borderRadius: "10px",
  "& .MuiButtonGroup-grouped": {
    margin: 5,
    border: "none",
  },
}));

const EqualWidthButton = styled(Button)(({ theme, selected }) => ({
  flex: "1",
  color: selected ? "black" : "white",
  backgroundColor: selected ? "#4bffdf" : "#12141A",
  "&:hover": {
    backgroundColor: selected ? "#4bffdf" : "#1D1F27",
  },
  textTransform: "none",
  "&&": { border: selected ? "none" : "1px solid white", borderRadius: "50px" },
}));

export default CustomButtonGroup;
