import { API, graphqlOperation } from 'aws-amplify';
import { getJWT } from './GMAmplifyInit';

export const q_jobListGet = `
  query jobListGet($page: Int) {
    jobListGet(page: $page) {
      data {
        id
        title
        description
        body
        date_posted
        date_to_perform
        location
        
      }

      errors {
        code
        message
      }
    }
  }
`;

export const m_anonymousUserCreate = `
  mutation anonymousUserCreate {
    anonymousUserCreate {
      data {
        id
        username
        email
        jwt_payload
      }
    }
  }
`;

export const m_anonymousUserUpdate = `
  mutation anonymousUserUpdate($name: String, $email: String, $archetype: String) {
    anonymousUserUpdate(name: $name, email: $email, archetype: $archetype) {
      data {
        id
        username
        email
      }
    }
  }
`;

export const m_quizInstanceCreate = `
  mutation quizInstanceCreate($quiz_def_id: String) {
    quizInstanceCreate(quiz_def_id: $quiz_def_id) {
      data {
        id

      }
    }
  }
`;


export const q_quizInstanceGet = `
  query quizInstanceGet($quiz_instance_id: String) {
    quizInstanceGet(quiz_instance_id: $quiz_instance_id) {
      errors {
        code
        message
      }

      data {
        id
        date_created
        answers
        result
        status
        quiz_def {
          id
          name
          iconURL
          questions {
            id
            question_content
            type
            answers {
              id
              answer_content
              answer_key
            }
          }
        }
      }
    }
  }
`;

export const m_quizInstanceResponseUpdate = `
  mutation quizInstanceResponseUpdate($quiz_question_id: String, $quiz_instance_id: String, $answer: String) {
    quizInstanceResponseUpdate(quiz_question_id: $quiz_question_id, quiz_instance_id: $quiz_instance_id, answer: $answer) {
      errors {
        message
        code
      }

      data {
        id
        result
      }
    }
  }
`;

export async function anonymousUserCreate() {
  try {
    const result = (await API.graphql(graphqlOperation(m_anonymousUserCreate, null, 'UNAUTH')));

    const ret = result.data.anonymousUserCreate;

    return ret;
  } catch (e) {
    console.error("Error creating user: ", e);
  }
}

export async function anonymousUserUpdate(name, email, archetype) {
  try {
    const inputParams = {
      name,
      email,
      archetype
    };

    const result = (await API.graphql(graphqlOperation(m_anonymousUserUpdate, inputParams, getJWT())));

    const ret = result.data.anonymousUserUpdate;

    return ret;
  } catch (e) {
    console.error("Error updating user: ", e);
  }
}

export async function jobListGet(page) {
  try {
    const inputParams = {
      page,
    };

    const result = (await API.graphql(graphqlOperation(q_jobListGet, inputParams, 'UNAUTH')));

    const ret = result.data.jobListGet;

    //Parse the location to objects..
    const updatedData = ret.data.map((item) => {
      return {
        ...item,
        location: JSON.parse(item.location)
      };
    });

    ret.data = updatedData;

    if (ret.errors && ret.errors.length > 0) {
      console.error('GraphQL errors:', ret.errors);
      return { errors: ret.errors };
    } else {
      return { data: ret.data };
    }
  } catch (error) {
    console.error('Error getting job list for user:', error);
  }
}

export async function quizInstanceCreate(quiz_def_id, jwt) {
  try {
    const inputParams = {
      quiz_def_id
    };

    const result = (await API.graphql(graphqlOperation(m_quizInstanceCreate, inputParams, jwt)));

    const ret = result.data.quizInstanceCreate;
    console.log('Object is: ' + JSON.stringify(result));

    if (ret.errors && ret.errors.length > 0) {
      console.error('GraphQL errors:', ret.errors);
      return { errors: ret.errors };
    } else {
      return { data: ret.data };
    }
  } catch (error) {
    console.error('Error creating quiz instance:', error);
  }
}

export async function quizInstanceGet(quiz_instance_id, jwt) {
  try {
    const inputParams = {
      quiz_instance_id
    };

    const result = (await API.graphql(graphqlOperation(q_quizInstanceGet, inputParams, jwt)));

    const ret = result.data.quizInstanceGet;
    if (ret.data && typeof ret.data.answers === 'string') {
      console.log('Parsing answers: ' + ret.data.answers);
      ret.data.answers = JSON.parse(ret.data.answers);
    }
    if (ret.data && typeof ret.data.result === 'string') {
      console.log('Parsing quiz instance at get: ' + ret.data.result);
      ret.data.result = JSON.parse(ret.data.result);
    } else {
      ret.data.result = { outcome: 'Unknown' };
    }
    console.log('Object is: ' + JSON.stringify(result));

    if (ret.errors && ret.errors.length > 0) {
      console.error('GraphQL errors:', ret.errors);
      return { errors: ret.errors };
    } else {
      return { data: ret.data };
    }
  } catch (error) {
    console.error('Error retrieving quiz instance:', error);
  }
}


export async function quizInstanceResponseUpdate(
  quiz_question_id,
  quiz_instance_id,
  answer
) {
  try {
    const inputParams = {
      quiz_question_id,
      quiz_instance_id,
      answer
    };

    const result = (await API.graphql(graphqlOperation(m_quizInstanceResponseUpdate, inputParams, getJWT())));

    const ret = result.data.quizInstanceResponseUpdate;

    if (ret.data && typeof ret.data.result === 'string') {
      ret.data.result = JSON.parse(ret.data.result);
    }
    console.log('Object is: ' + JSON.stringify(result));

    if (ret.errors && ret.errors.length > 0) {
      console.error('GraphQL errors:', ret.errors);
      return { errors: ret.errors };
    } else {
      return { data: ret.data };
    }
  } catch (error) {
    console.error('Error updating answer:', error);
  }
}