import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import IndividualSignUpForm from "components/forms/signup/IndividualSignUp.jsx";
import {
  SignUpWrapper,
  SignUpLeftSide,
  SignUpRightSide,
} from "screens/organisations/OrganisationStyledSections";

export default function SignUp() {
  return (
    <>
      <SignUpWrapper className="container">
        <SignUpLeftSide>
          <Heading3>
            Become a
            <span style={{ color: "var(--Lavender, #A6BCFF)" }}> Member</span>
          </Heading3>
          <Body1>
            Register and get access to ELLA, your personalised AI assistant and
            companion who will aid you in this time of transition, give you a
            personalised roadmap and advice to help you make the most of your
            midlife journey.
          </Body1>
        </SignUpLeftSide>
        <SignUpRightSide>
          <IndividualSignUpForm />
        </SignUpRightSide>
      </SignUpWrapper>
    </>
  );
}
