import React from "react";
import styled from "styled-components";

// components
import { Heading2 } from "components/TextStyles";
import GMLogo from "assets/svg/gmlogos/GMLogoSmallWhite";

export default function Bottom() {
  return (
    <Wrapper>
      <GMLogo width="48px" stroke="black" />
      <Heading2 textAlign="center" fontColor="black">
        The time is now to join the midlife revolution
      </Heading2>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: #a6bcff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 54px 13vw 64px 13vw;
  gap: 12px;
`;
