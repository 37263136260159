import React from "react";
import styled from "styled-components";

// Components
import { Heading1, Body1 } from "components/TextStyles";
import Button from "components/styledelements/Button";

// Assets
import HeaderImage2 from "assets/img/gmlogos/filledLogo.png";

export default function Projects() {
  return (
    <Wrapper id="ageing" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <Heading1>
          Ageing
          <span style={{ color: "var(--Lavender, #A6BCFF" }}> redefined </span>
        </Heading1>
        <Body1>
          Retirement (or midlife as we prefer to call it) is one of the most
          highly sought-after phases of life but can also be one of the most
          challenging and least understood. Reframing your narrative requires a
          plan including purpose, wellness, and connection.
        </Body1>
        <Body1>
          Start your journey by taking our free Re
          <span
            style={{
              fontSize: "120%",
              position: "relative",
              top: "2px",
              padding: "0px 2px",
            }}
          >
            ⋅
          </span>
          purposing quiz to help you navigate what's next.
        </Body1>
        <Button
          to="/repurpose-quiz"
          color="white"
          text="Re&#x200A;&middot;&#x200A;purpose Quiz"
          arrow="right"
        />
      </LeftSide>
      <AnchoredImage src={HeaderImage2} alt="Anchored Image" />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  width: 60%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 32px;

  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: left;
  }

  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const AnchoredImage = styled.img`
  // position: absolute;
  bottom: -43px; /* 0.2 * 500px = 100px */
  right: 0%;
  max-width: 90%;
  height: auto;
  z-index: 1;
`;
