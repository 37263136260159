import * as React from "react";

function SvgComponent(props) {
  const width = props.width || "40";
  return (
    <svg
      width={width}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19.5" stroke="#1D1F27" />
    </svg>
  );
}

export default SvgComponent;
