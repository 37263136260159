import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Top from "screens/registerinterest/sections/Top";
import Footer from "components/common/Footer";
import Bottom from "./sections/Bottom";

export default function RegisterInterest() {
  return (
    <>
      <TopNavbar />
      <Top />
      <Bottom />
      <Footer />
    </>
  );
}
