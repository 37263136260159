import React from "react";
import "style/index.css";

// components
import { Body1, Heading5 } from "components/TextStyles";
import {
  LowerWrapper,
  Tile,
  BackgroundWoman,
} from "screens/organisations/OrganisationStyledSections";

// assets
import GreyMattersEmblem from "assets/svg/gmlogos/GMLogoWhite";
import BackgroundWomanImg from "assets/img/organisations/backgroundWoman.png";

export default function LowerTextSection() {
  return (
    <>
      <LowerWrapper className="container">
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Empowering our Ageing Population
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Our AI-driven platform assists in developing personalised age
            transition plans for all citizens, ensuring a smooth transition into
            post-retirement life. This can enhance the quality of life for older
            citizens while maximising their continued contribution to society.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Becoming an Age Inclusive Society
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            As the working age population is decreasing, lifespans are extending
            and birth rates falling, it's crucial for governments to keep the
            over 45s contributing to society. Our platform encourages
            corporations, clubs and individuals to embrace age-inclusivity.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Tackling One of Society's Greatest Challenges
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Addressing the needs of an ageing population is one of the greatest
            economic and social challenges of our time. Our platform provides
            tools to improve societal resilience and to prepare for a future
            that respects and integrates the needs of its ageing citizens.
          </Body1>
        </Tile>
        <BackgroundWoman src={BackgroundWomanImg} />
      </LowerWrapper>
    </>
  );
}
