import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Body1, Heading2, Heading4 } from "components/TextStyles";

export default function AboutUs() {
  return (
    <>
      <Wrapper className="container flexSpaceCenter">
        <ValuesContent>
          <Heading2>Our Values</Heading2>
          <Heading4 fontColor="var(--Lavender, #A6BCFF)">
            Empathetic Innovation
          </Heading4>
          <Body1>
            We believe in harnessing the power of technology to create solutions
            that not only address challenges but also resonate with the human
            experience. We are driven by empathy, embracing the diverse needs of
            individuals, cultures and communities, and aiming to always make a
            positive impact on the world.
          </Body1>
          <Heading4 fontColor="var(--Lavender, #A6BCFF)">
            Sustainable Synergy
          </Heading4>
          <Body1>
            We are committed to building a sustainable future through the
            harmonious integration of technology, social and environmental
            responsibility. Be under no doubt we want to save the planet.
          </Body1>
          <Heading4 fontColor="var(--Lavender, #A6BCFF)">
            Age-inclusive Tech
          </Heading4>
          <Body1>
            Our mission is to redefine and dispel the negative beliefs around
            ageing. We are striving to reimagine a more age inclusive society,
            breaking down barriers, and empowering the longevity economy.
          </Body1>
          <Heading4 fontColor="var(--Lavender, #A6BCFF)">
            Ethical Intelligence
          </Heading4>
          <Body1>
            We understand the profound impact technology has on shaping the
            future. We are committed to ethical intelligence to ensure that as
            we enhance human capabilities through technology, we do so with
            integrity, transparency, and a dedication to the greater good.
          </Body1>
          <Heading4 fontColor="var(--Lavender, #A6BCFF)">
            Lifelong Learning
          </Heading4>
          <Body1>
            We’re always looking for opportunities to learn and grow,
            understanding that mastery is a journey. This means that we love
            questions and are always willing to adapt and change.
          </Body1>
        </ValuesContent>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 120px;
  @media (max-width: 960px) {
    order: 1;
    margin-top: 30px;
  }
`;

const ValuesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60vw;
  gap: 32px;
  padding-top: 10vh;

  @media (max-width: 960px) {
    width: 100%;
  }
`;
