import * as React from "react";

function SvgComponent({ width = 67, fillColor = "#A6BCFF" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 67 49"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
    >
      <path
        d="M67 0C65.8 5.06667 64.5333 10.5333 63.2 16.4C61.8667 22.2667 60.6667 28 59.6 33.6C58.5333 39.2 57.6667 44.3333 57 49H38L36.6 46.8C37.8 42.1333 39.3333 37.1333 41.2 31.8C43.0667 26.3333 45.1333 20.8667 47.4 15.4C49.6667 9.93334 51.8667 4.8 54 0H67ZM30 0C28.8 5.06667 27.5333 10.5333 26.2 16.4C24.8667 22.2667 23.6667 28 22.6 33.6C21.5333 39.2 20.6667 44.3333 20 49H1.2L0 46.8C1.2 42.1333 2.73333 37.1333 4.6 31.8C6.46667 26.3333 8.46667 20.8667 10.6 15.4C12.8667 9.93334 15.0667 4.8 17.2 0H30Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default SvgComponent;
