import React, { useState, useEffect } from "react";

// sections
import IntroPage from "./sections/IntroPage";
import QuestionPage from "./sections/QuestionPage";
import HAL from "./sections/HAL";
import ResultPage from "./sections/ResultPage";
import TopNavbar from "components/nav/TopNavbar";

// API
import {
  anonymousUserCreate,
  quizInstanceCreate,
  quizInstanceGet,
} from "graphql/GMAPI";
import { getJWT, isJwtExpired, setJWT } from "graphql/GMAmplifyInit";

const bgcolors = [
  "rgb(73,255,220)",
  "rgb(156,181,252)",
  "rgb(253,103,127)",
  "rgb(238,210,160)",
  "rgb(254,242,235)",
];

const QUIZ_DEF_REPURPOSE = "cls6xc4ff000008idckw3gy8n";

async function createUserAndGetJWT() {
  let user = await anonymousUserCreate();
  let jwt = user.data.jwt_payload;
  setJWT(jwt);

  return user;
}

async function createQuizInstance() {
  const quiz_instance_c = await quizInstanceCreate(
    QUIZ_DEF_REPURPOSE,
    getJWT()
  );

  return quiz_instance_c;
}

export default function Quiz() {
  const [currentStep, setCurrentStep] = useState("intro"); // "intro", "quiz", "hal", "results"
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [userHALAnswers, setUserHALAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const startQuiz = async () => {
    setIsLoading(true); // Start loading
    //Temp spot to test create user..
    let jwt = getJWT();

    try {
      if (!jwt || isJwtExpired(jwt)) {
        const user = await createUserAndGetJWT();
        jwt = getJWT();
      }

      let quiz_instance_c = undefined;
      try {
        quiz_instance_c = await createQuizInstance();
      } catch (e) {
        //If we're here, our JWT is likely expired... create a new user.
        const user = await createUserAndGetJWT();
        jwt = getJWT();
        quiz_instance_c = await createQuizInstance();
      }

      let quiz_instance = await quizInstanceGet(quiz_instance_c.data.id, jwt);

      let q = [];
      for (const question of quiz_instance.data.quiz_def.questions) {
        let tq = {
          question: question.question_content,
          quiz_id: quiz_instance.data.id,
          question_id: question.id,
          answers: [],
        };
        for (const answer of question.answers) {
          tq.answers.push({
            archetype: "cc", //Fixme: store archetype in question in db
            text: answer.answer_content,
            id: answer.id,
            archetype: answer.answer_key,
          });
        }
        q.push(tq);
      }

      setQuestions(q);
      setCurrentStep("quiz");
    } catch (error) {
      console.error("Error starting the quiz:", error);
      // Optionally handle errors, e.g., by showing an error message to the user
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleAnswer = (answer) => () => {
    // Update the answer for the current question with the key of the selected option
    const updatedAnswers = [...userAnswers];
    updatedAnswers[currentQuestionIndex] = answer;
    setUserAnswers(updatedAnswers);
    console.log(userAnswers);

    // Navigate to the next or previous question
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Quiz Finished
      setCurrentStep("HAL");
    }
  };

  const handleHALSubmit = (halAnswers) => {
    setUserHALAnswers(halAnswers);
    console.log("halAnswers ", halAnswers, " userHalAnswers: ", userHALAnswers);
    setCurrentStep("results");
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      setCurrentStep("intro");
    }
  };

  useEffect(() => {
    console.log("Current User Answers:", userAnswers);
  }, [userAnswers]);

  function calculateResult(userAnswers) {
    const cv = userAnswers.filter((answer) => answer === "cv").length;
    const cc = userAnswers.filter((answer) => answer === "cc").length;
    const pe = userAnswers.filter((answer) => answer === "pe").length;
    const ll = userAnswers.filter((answer) => answer === "ll").length;
    console.log(
      "cv count: ",
      cv,
      " cc count: ",
      cc,
      " pe count: ",
      pe,
      " ll count: ",
      ll
    );

    const counts = { cv, pe, cc, ll };

    const maxKey = Object.keys(counts).reduce((a, b) =>
      counts[a] > counts[b] ? a : b
    );

    console.log(maxKey);
    return maxKey;
  }

  return (
    <>
      {currentStep === "intro" && (
        <>
          <TopNavbar />
          <IntroPage onStart={startQuiz} loading={isLoading} />
        </>
      )}
      {currentStep === "quiz" && (
        <QuestionPage
          questionObj={questions[currentQuestionIndex]}
          onNext={(answer) => handleAnswer(answer)()}
          onBack={goToPreviousQuestion}
          currentAnswer={userAnswers[currentQuestionIndex]}
          bgcolor={bgcolors[currentQuestionIndex % bgcolors.length]}
          position={currentQuestionIndex / questions.length}
        />
      )}
      {currentStep === "HAL" && <HAL onSubmit={handleHALSubmit} />}
      {currentStep === "results" && (
        <ResultPage result={calculateResult(userAnswers)} />
      )}
    </>
  );
}
