import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import GreyTaskerSignUp from "components/forms/GreyTaskerSignUp";
import {
  SignUpWrapper,
  SignUpLeftSide,
  SignUpRightSide,
} from "screens/organisations/OrganisationStyledSections";

export default function SignUp() {
  return (
    <>
      <SignUpWrapper className="container">
        <SignUpLeftSide>
          <Heading3>
            <span style={{ color: "var(--Lavender, #A6BCFF)" }}>Register</span>{" "}
            your interest now
          </Heading3>
          <Body1>
            From volunteering to changing careers, to finding your ideal
            employee, GreyTasker has it all.
          </Body1>
        </SignUpLeftSide>
        <SignUpRightSide>
          <GreyTaskerSignUp />
        </SignUpRightSide>
      </SignUpWrapper>
    </>
  );
}
