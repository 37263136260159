import React from "react";

// components
import "style/index.css";
import { Heading4 } from "components/TextStyles";
import {
  TopTitle,
  TopWrapper,
  Gradient,
  TopImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/backgrounds/dandelionField.png";

export default function Top() {
  return (
    <>
      <TopImage image={image} />
      <Gradient />
      <TopWrapper className="container">
        <TopTitle>Resources</TopTitle>
        <Heading4>
          Browse our library of curated articles, press releases and latest
          newsworthy items, that Matter.
        </Heading4>
      </TopWrapper>
    </>
  );
}
