import React from "react";

// Sections
import TopNavbar from "../../components/nav/TopNavbar";
import Header from "./sections/Header";
import Projects from "./sections/Projects";
import Footer from "../../components/common/Footer";
import FAQ from "./sections/FAQ";
import DayLife from "./sections/DayLife";
import Experience from "./sections/Experience";
// import Partners from "../../components/Common/Partners";

export default function Landing() {
  return (
    <>
      <div className="video-container">
        <video autoPlay muted playsInline loop>
          <source src="/dandelions.mp4" type="video/mp4" />
        </video>
        <TopNavbar />
        <Header />
      </div>
      {/* <Partners /> */}
      <Experience />
      <Projects />
      <DayLife />
      <FAQ />
      <Footer />
    </>
  );
}
