import React from "react";

// screens
import TopNavbar from "components/nav/TopNavbar";
import FullManifesto from "./sections/FullManifesto.jsx";
import SignUp from "./sections/SignUp.jsx";

export default function Manifesto() {
  return (
    <>
      <TopNavbar />
      <FullManifesto />
      <SignUp />
    </>
  );
}
