import * as React from "react";

function SvgComponent({ width = 67, fillColor = "#A6BCFF" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 67 49"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
    >
      <path
        d="M0 49C1.2 43.9333 2.46667 38.4667 3.8 32.6C5.13334 26.7333 6.33333 21 7.4 15.4C8.46667 9.8 9.33333 4.66666 10 -7.62939e-06H29L30.4 2.19999C29.2 6.86666 27.6667 11.8667 25.8 17.2C23.9333 22.6667 21.8667 28.1333 19.6 33.6C17.3333 39.0667 15.1333 44.2 13 49H0ZM37 49C38.2 43.9333 39.4667 38.4667 40.8 32.6C42.1333 26.7333 43.3333 21 44.4 15.4C45.4667 9.8 46.3333 4.66666 47 -7.62939e-06H65.8L67 2.19999C65.8 6.86666 64.2667 11.8667 62.4 17.2C60.5333 22.6667 58.5333 28.1333 56.4 33.6C54.1333 39.0667 51.9333 44.2 49.8 49H37Z"
        fill={fillColor}
      />
    </svg>
  );
}

export default SvgComponent;
