import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="clamp(100px, 19vw, 400px)"
      height="clamp(100px, 7.6vw, 400px)"
      viewBox="0 0 204 87"
      fill="none"
    >
      <path
        d="M2.04211 85.8187C3.48808 79.3119 17.6149 70.3017 22.2988 65.562C32.7903 54.9457 42.6769 43.6232 51.3947 31.494C53.0367 29.2095 70.2745 2.20194 67.4159 1.38521C62.7502 0.0521504 53.7838 5.6433 49.4612 7.00183C40.1371 9.93226 32.3081 13.7583 26.166 21.734C18.3273 31.9125 12.9588 42.499 27.455 47.331C37.0909 50.543 47.3137 51.0141 57.3797 51.0141C62.2715 51.0141 53.5776 52.3169 52.4076 52.6714C45.1677 54.8653 38.6247 57.4868 31.8747 60.9583C24.9415 64.5239 32.3723 63.4594 35.5577 62.9839C45.9925 61.4265 58.4709 56.1703 68.7971 56.1703C70.3147 56.1703 67.1327 59.4326 67.6922 59.2088C72.758 57.1825 77.5395 50.8171 80.3066 46.4103C87.5926 34.8066 81.5956 44.2392 81.5956 52.4873C81.5956 59.9149 77.891 52.1936 70.8227 51.1061C66.8439 50.494 65.8132 52.4357 70.7307 52.7635C79.9793 53.3801 89.0248 55.0236 98.1693 55.9862C101.155 56.3004 98.1693 57.8108 98.1693 59.3009C98.1693 62.3262 106.145 60.9583 108.113 60.9583C111.454 60.9583 117.899 60.4872 119.531 56.8148C120.808 53.9415 118.858 52.6714 123.03 52.6714C133.61 52.6714 144.664 55.8521 155.164 54.2367C160.303 53.4462 165.089 49.1411 170.725 48.0676C179.167 46.4597 184.139 51.0733 191.81 53.408C192.784 53.7043 200.097 55.6874 200.926 55.0654C204.014 52.7493 202.001 51.6971 199.268 51.0141"
        stroke="#A6BCFF"
        strokeWidth="2.34384"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
