import { Card, CardContent } from "@mui/material";
import { Body2, Body3 } from "components/TextStyles";
import Button from "components/styledelements/Button";
import styled from "styled-components";

const Tag = ({ text }) => (
  <div
    style={{
      position: "absolute",
      top: "16px",
      left: "24px",
      backgroundColor: "#869ED1",
      borderRadius: "40px",
      padding: "2px 10px",
    }}
  >
    <Body3>{text}</Body3>
  </div>
);

export function ArticlesContainer({
  image,
  isPopular,
  category,
  date,
  title,
  subtitle,
  articleURL,
}) {
  const ImageContainer = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  `;

  const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `;

  return (
    <Card
      sx={{ bgcolor: "#1D1F27", borderRadius: 4, position: "relative" }}
      elevation={0}
    >
      <ImageContainer>
        <Image src={image} alt="article header" />
      </ImageContainer>
      {isPopular && <Tag text="Popular" />}
      <CardContent
        sx={{ display: "flex", flexDirection: "column", gap: 2, p: 4 }}
      >
        <Body3>
          <span style={{ color: "#4bffdf" }}>{category}</span> | {date}
        </Body3>
        <Body2>{title}</Body2>
        <Body3>{subtitle}</Body3>
        <Button
          href={articleURL}
          arrow="right"
          text="More"
          color="white"
          style={{ marginTop: "24px" }}
          size="small"
        />
      </CardContent>
    </Card>
  );
}

export function NewsContainer({ image, isTrending, title }) {
  const CardContentStyles = {
    background: `linear-gradient(0deg, rgba(0,0,0,0.5) 26.24%, rgba(217,217,217,0) 71.48%), #12141a url(${image}) center center / cover no-repeat`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "40% 24px 24px 24px",
  };

  return (
    <Card
      sx={{
        bgcolor: "#1D1F27",
        position: "relative",
      }}
      elevation={0}
    >
      {isTrending && <Tag text="Trending" />}
      <CardContent style={CardContentStyles}>
        <Body2>{title}</Body2>
      </CardContent>
    </Card>
  );
}
