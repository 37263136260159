import React, { useState } from "react";
import styled from "styled-components";

// components
import {
  StyledFormControl,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
  Row,
} from "components/StyledMuiElements";
import { Body1, Heading3 } from "components/TextStyles";
import { MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/styledelements/Button";
import { anonymousUserUpdate } from "graphql/GMAPI";

export default function Modal({ result }) {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    ageRange: "",
    errors: {},
  };

  const [modalOpen, setModalOpen] = useState(true);
  const [formState, setFormState] = useState(initialState);
  // const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: validateField(name, value),
      },
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return value.length > 0 ? "" : "This field is required.";
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
          ? ""
          : "Invalid email format.";
      default:
        return "";
    }
  };

  const isFormValid = () => {
    const areFieldsFilled =
      formState.firstName &&
      formState.lastName &&
      formState.email &&
      formState.ageRange;
    const areNoErrors = Object.values(formState.errors).every(
      (error) => error === ""
    );
    return areFieldsFilled && areNoErrors;
  };

  const handleSubmit = () => {
    submitResults(
      `${formState.firstName} ${formState.lastName}`,
      formState.email
    );
    closeModal();
  };

  const submitResults = (name, email) => {
    anonymousUserUpdate(name, email, result);
  };

  return (
    <>
      <Overlay visible={modalOpen}>
        <Wrapper>
          <CloseButton onClick={closeModal}>
            <CloseIcon fontSize="large" />
          </CloseButton>
          <Heading3 fontColor="black" lineHeight="100%">
            Thank you for taking our Quiz!
          </Heading3>
          <Body1 fontColor="black">
            Please enter your details if you would like to save your results for
            when you continue your journey with Grey Matters
          </Body1>
          <FormWrapper>
            <Row>
              <StyledTextField
                fullWidth
                name="firstName"
                onChange={handleChange}
                variant="outlined"
                label="First Name"
                bordercolor="#555"
                autofillfillcolor="white"
                autofilltextcolor="#555"
                InputLabelProps={{ style: { color: "#555" } }}
                InputProps={{ style: { color: "#555" } }}
                helperText={formState.errors.firstName}
                error={!!formState.errors.firstName}
              />
              <StyledTextField
                fullWidth
                name="lastName"
                onChange={handleChange}
                variant="outlined"
                label="Last Name"
                bordercolor="#555"
                autofillfillcolor="white"
                autofilltextcolor="#555"
                InputLabelProps={{ style: { color: "#555" } }}
                InputProps={{ style: { color: "#555" } }}
                helperText={formState.errors.lastName}
                error={!!formState.errors.lastName}
              />
            </Row>
            <StyledTextField
              fullWidth
              name="email"
              onChange={handleChange}
              variant="outlined"
              label="Email"
              bordercolor="#555"
              autofillfillcolor="white"
              autofilltextcolor="#555"
              InputLabelProps={{ style: { color: "#555" } }}
              InputProps={{ style: { color: "#555" } }}
              helperText={formState.errors.email}
              error={!!formState.errors.email}
            />
            <StyledFormControl fullWidth variant="outlined">
              <StyledInputLabel htmlFor="age-range" style={{ color: "#555" }}>
                Age Range
              </StyledInputLabel>
              <StyledSelect
                id="age-range"
                value={formState.ageRange}
                name="ageRange"
                onChange={handleChange}
                label="Age Range"
                MenuProps={StyledMenuProps}
                bordercolor="#555"
                iconcolor="#555"
                textcolor="#555"
                inputProps={{
                  style: { color: "#555" },
                }}
              >
                <MenuItem value="45 - 55">45 - 55</MenuItem>
                <MenuItem value="55 - 65">55 - 65</MenuItem>
                <MenuItem value="65 - 75">65 - 75</MenuItem>
                <MenuItem value="75+">75+</MenuItem>
              </StyledSelect>
            </StyledFormControl>
          </FormWrapper>
          <ButtonsWrapper>
            <Button
              onClick={closeModal}
              text="I'll do this later"
              color="transparent"
              // TODO: fix paddingLeft
              style={{ paddingLeft: "12px", alignSelf: "center" }}
            />
            <Button
              disabled={!isFormValid()}
              onClick={() => isFormValid() && handleSubmit()}
              color="black"
              arrow="right"
              text="Submit"
              style={{ alignSelf: "center" }}
            />
          </ButtonsWrapper>
        </Wrapper>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  transition: opacity 0.2s ease, visibility 0.2s ease;

  /* Keeping the fade-in animation */
  & > section {
    animation: ${(props) => (props.visible ? "fadeIn 0.3s ease-out" : "none")};
  }
`;

const Wrapper = styled.section`
  position: relative;

  background-color: #fdfdfd;
  padding: 60px 80px 30px 80px;
  @media (max-width: 560px) {
    padding: 60px 20px 30px 20px;
  }
  border-radius: 8px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.15);

  max-width: 700px;
  width: 100%;
  margin: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  animation: fadeIn 0.3s ease-out;
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(0%, 2%);
    }
    to {
      opacity: 1;
      transform: translate(0%, 0%);
    }
  }

  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 10%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

const StyledMenuProps = {
  PaperProps: {
    sx: {
      backgroundColor: "white",
      ".MuiMenuItem-root": {
        color: "#555",
        "&:hover": {
          backgroundColor: "#eee",
        },
        "&.Mui-selected": {
          color: "white",
          backgroundColor: "#555",
        },
      },
    },
  },
};
