import * as React from "react";

function SvgComponent(props) {
    const { className } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="8"
            viewBox="0 0 9 5"
            fill="none"
            className={className}>
            <path d="M4.5 5L0.602885 0.5L8.39711 0.500001L4.5 5Z" fill="white" />
        </svg>
    );
}

export default SvgComponent;
