import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Heading3, Body1 } from "components/TextStyles";
import TopNavbar from "components/nav/TopNavbar";
import { TopWrapper } from "screens/organisations/OrganisationStyledSections";

export default function Top() {
  return (
    <>
      <TopNavbar />
      <TopWrapper className="container">
        <TopTitle>Privacy and Ethical Use of AI Policy</TopTitle>

        <Heading3>Section 1: Introduction and Purpose</Heading3>
        <Body1>
          1.1 Overview This policy outlines the principles and practices for the
          responsible use of Artificial Intelligence (AI) and data privacy at
          Grey Matters, an ageing diversity SaaS platform. It is designed to
          ensure ethical, fair, and lawful use of AI technologies in our
          services.
          <br />
          <br />
          1.2 Purpose The purpose of this policy is to establish a framework
          for:
          <br />
          • Ensuring the responsible development, deployment, and use of AI
          technologies.
          <br />
          • Protecting the privacy and personal data of our users.
          <br />
          • Mitigating risks associated with AI systems.
          <br />
          • Promoting transparency, fairness, and accountability in our AI
          applications.
          <br />
          <br />
          1.3 Scope This policy applies to all AI technologies and data
          practices at Grey Matters, including but not limited to data
          collection, storage, processing, and AI-driven decision-making
          processes.
          <br />
          <br />
          1.4 Commitment Grey Matters is committed to upholding the highest
          standards of ethical conduct and compliance with all applicable laws
          and regulations in the use of AI and data management.
          <br />
        </Body1>

        <Heading3>Section 2: Accountability</Heading3>
        <Body1>
          2.1 Responsibility
          <br />
          • Senior leadership at Grey Matters is responsible for ensuring
          compliance with this policy.
          <br />
          • All employees, contractors, and partners are expected to adhere to
          these guidelines in their work.
          <br />
          <br />
          2.2 Impact Assessment
          <br />
          • Regular assessments will be conducted to evaluate the impact of AI
          systems on users, particularly the ageing population, and society.
          <br />
          • Potential risks, biases, and ethical considerations will be
          identified and addressed.
          <br />
          <br />
          2.3 Oversight Mechanisms
          <br />
          • Establishment of an AI Ethics Board to oversee the responsible use
          of AI.
          <br />
          • Regular audits and reviews of AI systems for ethical compliance.
          <br />
          <br />
          2.4 Human Oversight
          <br />
          • Ensuring human oversight in AI decision-making processes to avoid
          over-reliance on automated systems.
          <br />
          • Training staff to understand and manage AI systems responsibly.
          <br />
        </Body1>
        <Heading3>Section 3: Transparency and Communication</Heading3>
        <Body1>
          3.1 System Intelligibility
          <br />
          • Efforts will be made to make AI systems' decisions and workings
          understandable to our users.
          <br />
          • Technical details will be communicated in a user-friendly manner.
          <br />
          <br />
          3.2 Communication Strategies
          <br />
          • Clear, transparent communication about the role and limitations of
          AI in our services.
          <br />
          • User-friendly explanations of how AI impacts their experience and
          decisions made on their behalf.
          <br />
          <br />
          3.3 Disclosure of AI Involvement
          <br />
          • Transparent disclosure of AI-generated content and decisions.
          <br />
          • Users will be informed when they are interacting with AI systems.
          <br />
        </Body1>

        <Heading3>Section 4: Fairness and Inclusiveness</Heading3>
        <Body1>
          4.1 Ensuring Fairness
          <br />
          • AI systems will be designed to provide equitable service across
          different demographic groups.
          <br />
          • Regular testing for bias and fairness in AI algorithms.
          <br />
          <br />
          4.2 Inclusiveness
          <br />
          • AI systems and services will be accessible to all users, including
          those with disabilities.
          <br />
          • Special attention will be given to the unique needs of the ageing
          population.
          <br />
        </Body1>

        <Heading3>Section 5: Reliability and Safety</Heading3>
        <Body1>
          5.1 Reliability Standards
          <br />
          • Grey Matters will adhere to established industry standards to ensure
          the reliability and safety of AI systems.
          <br />
          • Continuous monitoring for potential faults or failures in AI
          applications.
          <br />
          <br />
          5.2 Failure Management
          <br />
          • Prompt and effective response to any system failures or
          malfunctions.
          <br />
          • Implementation of robust backup and recovery procedures to maintain
          service continuity.
          <br />
          <br />
          5.3 Risk Mitigation
          <br />
          • Development of risk mitigation strategies for potential AI system
          vulnerabilities.
          <br />
          • Regular risk assessments and updates to safety protocols.
          <br />
        </Body1>

        <Heading3>Section 6: Privacy and Security</Heading3>
        <Body1>
          6.1 Data Privacy
          <br />
          • Strict adherence to data privacy laws and regulations.
          <br />
          • Ensuring that personal data is collected, stored, and processed
          securely and ethically.
          <br />
          <br />
          6.2 Security Measures
          <br />
          • Implementation of advanced security measures to protect against
          unauthorized access to or misuse of AI systems and data.
          <br />
          • Regular security audits and updates to maintain the highest level of
          data protection.
          <br />
          <br />
          6.3 Data Use Transparency
          <br />
          • Clear communication to users about how their data is used.
          <br />
          • Providing users with control over their personal data and choices
          about its use.
          <br />
        </Body1>

        <Heading3>Section 7: Implementation and Compliance</Heading3>
        <Body1>
          7.1 Employee Training and Awareness
          <br />
          • Regular training programs for employees on responsible AI practices
          and data privacy standards.
          <br />
          • Ensuring all staff are aware of their responsibilities under this
          policy.
          <br />
          <br />
          7.2 Monitoring and Evaluation
          <br />
          • Ongoing monitoring of AI systems for adherence to policy standards.
          <br />
          • Periodic evaluation of policy effectiveness and compliance.
          <br />
          <br />
          7.3 Reporting Mechanisms
          <br />
          • Establishing channels for reporting non-compliance or ethical
          concerns related to AI and data privacy.
          <br />
          • Encouraging a culture of openness and accountability.
          <br />
        </Body1>

        <Heading3>Section 8: Review and Updates</Heading3>
        <Body1>
          8.1 Regular Review
          <br />
          • This policy will be reviewed annually to ensure it remains relevant
          and effective.
          <br />
          • Consideration of new developments in AI, privacy laws, and ethical
          standards.
          <br />
          <br />
          8.2 Policy Updates
          <br />
          • Updates to the policy will be made as necessary to reflect changes
          in technology, regulations, and best practices in AI and data
          management.
          <br />
          Conclusion
          <br />
          Grey Matters is dedicated to the responsible and ethical use of AI
          technology and the protection of user data privacy. This policy
          reflects our commitment to these principles and our responsibility to
          provide safe, fair, and beneficial services to the ageing population.
          <br />
        </Body1>
        <br />
        <br />
      </TopWrapper>
    </>
  );
}

export const TopTitle = styled.section`
  z-index: 1;
  color: var(--white, #fff);
  font-feature-settings: "clig" off, "liga" off;

  /* GM/Heading/Heading 1 */
  font-family: Inter;
  font-size: clamp(30px, 7vw, 50px);
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 96px */
  width: 70vw;

  margin-top: 80px;

  @media (max-width: 960px) {
    font-size: clamp(48px, 6vw, 64px);
  }

  @media (max-width: 560px) {
    font-size: clamp(40px, 5vw, 54px);
  }
`;
