import * as React from "react";

function SvgComponent(props) {
  const marginTop = props.marginTop || "0px";
  const marginBottom = props.marginBottom || "0px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="109"
      viewBox="0 0 54 109"
      fill="none"
      style={{ marginTop, marginBottom }}
    >
      <path
        d="M27 0L27 107.051"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M1 80.9507L27 107.051L53 80.9507"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

export default SvgComponent;
