import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Top from "./sections/Top";
// import JoinBeta from "components/common/RegisterNow";
import Footer from "components/common/Footer";
import UpperTextSection from "./sections/UpperTextSection";
import LowerTextSection from "./sections/LowerTextSection";
import SignUp from "./sections/SignUp";
import Services from "./sections/Services";
import Manifesto from "./sections/Manifesto";

export default function Corporations() {
  return (
    <>
      <TopNavbar />
      <Top />
      <UpperTextSection />
      <LowerTextSection />
      <Services />
      <Manifesto />
      <SignUp />
      <Footer />
    </>
  );
}
