import styled from "styled-components";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CheckOn from "assets/svg/icons/forms/CheckOn";
import CheckOff from "assets/svg/icons/forms/CheckOff";

export const StyledSelect = styled(Select)`
  && {
    color: ${(props) => props.textcolor || "white"};
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.bordercolor || "rgba(228, 219, 233, 0.25)"};
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.bordercolor || "rgba(228, 219, 233, 0.25)"};
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.bordercolor || "rgba(228, 219, 233, 0.25)"};
    }
    .MuiSvgIcon-root {
      fill: ${(props) => props.iconcolor || "white"} !important;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    margin-top: 1rem;
    color: white;
    border-color: white;
    .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.bordercolor || "rgba(228, 219, 233, 0.25)"} !important;
    }
    ,
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.bordercolor || "rgba(228, 219, 233, 0.25)"};
    }
    ,
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) =>
        props.bordercolor || "rgba(228, 219, 233, 0.25)"} !important;
    }

    // transparent autofill background for webkit browsers
    .MuiOutlinedInput-input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px
        ${(props) => props.autofillfillcolor || "#12141a"} inset;
      -webkit-text-fill-color: ${(props) =>
        props.autofilltextcolor || "#white"} !important;
    }

    // transparent autofill background for firefox
    .MuiOutlinedInput-input:-moz-autofill,
    .MuiOutlinedInput-input:-moz-autofill-preview,
    .MuiOutlinedInput-input:-moz-ui-valid {
      background: ${(props) => props.autofillfillcolor || "#12141a"} !important;
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 1rem;
    .MuiInputLabel-outlined.Mui-focused {
      color: white;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    color: white;
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: white;
`;

export const Row = styled.section`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  @media (max-width: 1440px) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: 1080px) {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const AquaCheckBox = styled(() => (
  <Checkbox icon={<CheckOff />} checkedIcon={<CheckOn />} />
))``;

export const WhiteCheckBox = styled(Checkbox)`
  && {
    color: white;
    &.Mui-checked {
      color: white;
    }
  }
`;

export const StyledMenuProps = {
  PaperProps: {
    sx: {
      backgroundColor: "#12141A",
      ".MuiMenuItem-root": {
        color: "white",
        "&:hover": {
          backgroundColor: "#1D1F27",
        },
        "&.Mui-selected": {
          backgroundColor: "#0E1A39",
        },
      },
    },
  },
};
