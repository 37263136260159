import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import JoinBeta from "components/common/RegisterNow";
import Footer from "components/common/Footer";
import Top from "./sections/Top";
import UpperTextSection from "./sections/UpperTextSection";
import LowerTextSection from "./sections/LowerTextSection";
import RepurposeQuiz from "./sections/RepurposeQuiz";
import SignUp from "./sections/SignUp";

export default function Individuals() {
  return (
    <>
      <TopNavbar />
      <Top />
      <UpperTextSection />
      <LowerTextSection />
      <RepurposeQuiz />
      <SignUp />
      <JoinBeta />
      <Footer />
    </>
  );
}
