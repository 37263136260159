import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  LeftSide,
  RightSide,
  UpperWrapper,
  UpperImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/clubs/clubs2.png";

export default function UpperTextSection() {
  return (
    <>
      <UpperWrapper className="container">
        <LeftSide>
          <Heading3>Bringing our community together</Heading3>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Welcome to Grey Matters! We’re revolutionising the concept of ageing
            by turning it into an asset. Join us as we empower clubs and
            communities to embrace midlife like never before.
          </Body1>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Grey Matters has an age inclusive jobs board (GreyTasker) which you
            can use to hire great over 45s talent!
          </Body1>
        </LeftSide>
        <RightSide>
          <UpperImage src={image} />
        </RightSide>
      </UpperWrapper>
    </>
  );
}
