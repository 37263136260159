import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import ClubManifestoSignUp from "components/forms/manifesto/ClubManifestoSignUp";
import {
  SignUpWrapper,
  SignUpLeftSide,
  SignUpRightSide,
} from "screens/organisations/OrganisationStyledSections";

export default function SignUp() {
  return (
    <>
      <SignUpWrapper className="container">
        <SignUpLeftSide>
          <Heading3>
            Unlock the potential of your
            <span style={{ color: "var(--Lavender, #A6BCFF)" }}> Club </span>
            with Grey Matters
          </Heading3>
          <Body1>
            Grey Matters offers your club exclusive access to specialised
            resources and tools specifically tailored to the needs and interests
            of clubs. This includes online clubs, calendars and events,
            communications forums and general support to enhance your club's
            operations and member experiences.
          </Body1>
          <Body1>
            By signing up, your club will become part of our vibrant and growing
            community.
          </Body1>
        </SignUpLeftSide>
        <SignUpRightSide>
          <ClubManifestoSignUp />
        </SignUpRightSide>
      </SignUpWrapper>
    </>
  );
}
