import React, {
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from "react";
import Link from "components/elements/ScrollToTopLink";
import styled, { keyframes } from "styled-components";

// Components
import Sidebar from "../nav/Sidebar";
import Backdrop from "../elements/Backdrop";
import {
  GreymattersHeaderName,
  RotatingDropDownArrow,
} from "components/styledelements/GeneralStyledElements";
import {
  TopNavTab,
  TopNavTabText,
  TopNavTabChild,
} from "components/styledsections/NavBarStyles";
import { NoWrapScrollLink } from "components/styledelements/NoWrapScrollLink";
import Button from "components/styledelements/Button";

// Assets
import LogoIcon from "../../assets/svg/gmlogos/GMLogoSmallWhite";
import BurgerIcon from "../../assets/svg/icons/BurgerIcon";

const initialState = {
  sidebarOpen: false,
  organisationsDropdownOpen: false,
  insightsDropdownOpen: false,
  organisationsDropdownTimer: null,
  insightsDropdownTimer: null,
  hoveredTopNavTabChild: null,
};

function dropdownReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, sidebarOpen: !state.sidebarOpen };
    case "SHOW_ORGANISATIONS":
      return { ...state, organisationsDropdownOpen: true };
    case "HIDE_ORGANISATIONS":
      clearTimeout(state.organisationsDropdownTimer);
      return {
        ...state,
        organisationsDropdownOpen: false,
        organisationsDropdownTimer: null,
      };
    case "SHOW_INSIGHTS":
      return { ...state, insightsDropdownOpen: true };
    case "HIDE_INSIGHTS":
      clearTimeout(state.insightsDropdownTimer);
      return {
        ...state,
        insightsDropdownOpen: false,
        insightsDropdownTimer: null,
      };
    case "SET_HOVERED_TOP_NAV_TAB_CHILD":
      return { ...state, hoveredTopNavTabChild: action.payload };
    case "SET_ORGANISATIONS_TIMER":
      return { ...state, organisationsDropdownTimer: action.timer };
    case "SET_INSIGHTS_TIMER":
      return { ...state, insightsDropdownTimer: action.timer };
    default:
      return state;
  }
}

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [state, dispatch] = useReducer(dropdownReducer, initialState);
  const timeoutRef = useRef();

  const handleScroll = useCallback(() => {
    setY(window.scrollY);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const showDropdown = (type) => {
    const timerType = `${type.toLowerCase()}DropdownTimer`;

    if (state[timerType]) {
      clearTimeout(state[timerType]);
      dispatch({ type: `SET_${type}_TIMER`, timer: null });
    }

    dispatch({ type: `SHOW_${type}` });
  };

  const hideDropdown = (type) => {
    if (state[`${type.toLowerCase()}DropdownTimer`]) {
      clearTimeout(state[`${type.toLowerCase()}DropdownTimer`]);
    }

    timeoutRef.current = setTimeout(() => {
      dispatch({ type: `HIDE_${type}` });
    }, 300);

    dispatch({ type: `SET_${type}_TIMER`, timer: timeoutRef.current });
  };

  // ensure we clear timeouts on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Sidebar
        sidebarOpen={state.sidebarOpen}
        toggleSidebar={() => dispatch({ type: "TOGGLE_SIDEBAR" })}
      />
      {state.sidebarOpen && (
        <Backdrop toggleSidebar={() => dispatch({ type: "TOGGLE_SIDEBAR" })} />
      )}
      <Wrapper
        className="animate"
        style={
          y > 60
            ? { height: "80px", backgroundColor: "var(--slate, #12141A" }
            : { height: "100px" }
        }
      >
        <NoWrapScrollLink to="/">
          <LogoIcon />
          <GreymattersHeaderName>
            Grey Matters{" "}
            <span style={{ fontSize: 10, verticalAlign: "top" }}>®</span>
          </GreymattersHeaderName>
        </NoWrapScrollLink>
        <BurgerWrapper onClick={() => dispatch({ type: "TOGGLE_SIDEBAR" })}>
          <BurgerIcon />
        </BurgerWrapper>
        <UlWrapper>
          <li className="pointer">
            <Link to="/about">
              <TopNavTabText>About</TopNavTabText>
            </Link>
          </li>
          <li
            onMouseEnter={() => showDropdown("ORGANISATIONS")}
            onMouseLeave={() => hideDropdown("ORGANISATIONS")}
          >
            <TopNavTab>
              <TopNavTabText>Organisations</TopNavTabText>
              <RotatingDropDownArrow
                isActive={state.organisationsDropdownOpen}
                fromDegree="-90"
                toDegree="0"
              />
            </TopNavTab>
            {state.organisationsDropdownOpen && (
              <DropdownMenu>
                <Link
                  to="/organisations/corporations"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "corporations",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "corporations"}
                  >
                    Corporations
                  </TopNavTabChild>
                </Link>
                <Link
                  to="/organisations/clubs"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "clubs",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "clubs"}
                  >
                    Clubs
                  </TopNavTabChild>
                </Link>
                <Link
                  to="/organisations/individuals"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "individuals",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "individuals"}
                  >
                    Individuals
                  </TopNavTabChild>
                </Link>
                <Link
                  to="/organisations/public-sector"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "public-sector",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "public-sector"}
                  >
                    Public Sector
                  </TopNavTabChild>
                </Link>
              </DropdownMenu>
            )}
          </li>
          <li
            onMouseEnter={() => showDropdown("INSIGHTS")}
            onMouseLeave={() => hideDropdown("INSIGHTS")}
          >
            <TopNavTab>
              <TopNavTabText>Insights</TopNavTabText>
              <RotatingDropDownArrow
                isActive={state.insightsDropdownOpen}
                fromDegree="-90"
                toDegree="0"
              />
            </TopNavTab>
            {state.insightsDropdownOpen && (
              <DropdownMenu>
                <Link
                  to="/insights/media"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "media",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "media"}
                  >
                    Media
                  </TopNavTabChild>
                </Link>
                <Link
                  to="/insights/resources"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "resources",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "resources"}
                  >
                    Resources
                  </TopNavTabChild>
                </Link>
                <Link
                  to="/insights/manifesto"
                  onMouseEnter={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: "manifesto",
                    })
                  }
                  onMouseLeave={() =>
                    dispatch({
                      type: "SET_HOVERED_TOP_NAV_TAB_CHILD",
                      payload: null,
                    })
                  }
                >
                  <TopNavTabChild
                    isActive={state.hoveredTopNavTabChild === "manifesto"}
                  >
                    Manifesto
                  </TopNavTabChild>
                </Link>
              </DropdownMenu>
            )}
          </li>
          <li className="pointer">
            <Link to="/greytasker">
              <TopNavTabText>GreyTasker</TopNavTabText>
            </Link>
          </li>
          {/* <li className="pointer">
            <Link to="/repurpose-quiz">
              <TopNavTabText>
                Re
                <span
                  style={{
                    position: "relative",
                    bottom: "4px",
                    padding: "2px",
                  }}
                >
                  .
                </span>
                purpose Quiz
              </TopNavTabText>
            </Link>
          </li> */}
          <li className="pointer">
            <Link to="/greyacademy">
              <TopNavTabText>GreyAcademy</TopNavTabText>
            </Link>
          </li>
          <li className="pointer">
            <Link to="/repurpose-quiz">
              <TopNavTabText>
                Re
                <span
                  style={{
                    fontSize: "120%",
                    position: "relative",
                    top: "2px",
                    padding: "0px 2px",
                  }}
                >
                  ⋅
                </span>
                purpose Quiz
              </TopNavTabText>
            </Link>
          </li>
          <li className="pointer">
            <Link to="/contact">
              <TopNavTabText>Contact Us</TopNavTabText>
            </Link>
          </li>
        </UlWrapper>
        <UlWrapperRight>
          <Button to="/sign-in" text="Sign In" color="white" arrow="right" />
        </UlWrapperRight>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;

  z-index: 999;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0 auto;
  padding: 0 5vw;

  @media (max-width: 1400px) {
    padding: 0 2vw;
  }

  @media (max-width: 1079px) {
    padding: 0 5vw;
  }
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  cursor: pointer;
  @media (max-width: 1079px) {
    display: block;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 0 32px 0 16px;

  @media (max-width: 1400px) {
    gap: 24px;
  }

  @media (max-width: 1079px) {
    display: none;
  }
`;

const UlWrapperRight = styled.ul`
  display: flex;
  align-items: center;

  @media (max-width: 1079px) {
    display: none;
  }
`;

const fadeInFromTop = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3px) translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateX(-3px) translateY(10px);
  }
`;

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;

  // Use the keyframe animation
  animation: ${fadeInFromTop} 0.2s ease-out;
  transform: translateX(-3px) translateY(10px);

  background-color: rgba(29, 31, 39, 0.8);
  box-shadow: 0px 24px 40px 0px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(50px);
  opacity: 1;
  border-radius: 12px;

  z-index: 1;

  a {
    color: black;
    padding: 14px 20px;
    display: block;
    width: 100%;
    border-radius: 0;

    &:hover {
      background-color: var(--Slate, #12141a);
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
`;
