import React, { useState } from "react";
import "style/index.css";

// components
import { StyledTextField } from "components/StyledMuiElements";
import { Body1 } from "components/TextStyles";
import { CircularProgress } from "@mui/material";
import { FormWrapper } from "screens/organisations/OrganisationStyledSections";
import Button from "components/styledelements/Button";

export default function GreyTaskerSignUp() {
  const initialState = {
    fullName: "",
    email: "",
    howYouFoundUs: "",
    errors: {},
  };

  const [formState, setFormState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
      errors: {
        ...prevState.errors,
        [name]: validateField(name, newValue),
      },
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "fullName":
        return value.length > 0 ? "" : "This field is required.";
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
          ? ""
          : "Invalid email format.";
      case "howYouFoundUs":
        return value.length >= 5 && value.length <= 10000
          ? ""
          : "Please tell us more about how you found us!";
      default:
        return "";
    }
  };

  const isFormValid = () => {
    const areFieldsFilled =
      formState.fullName && formState.email && formState.howYouFoundUs;
    const areNoErrors = Object.values(formState.errors).every(
      (error) => error === ""
    );
    return areFieldsFilled && areNoErrors;
  };

  const sendMessage = () => {
    setIsSubmitting(true);

    const formData = {
      type: "GreyTaskerSignUp",
      name: formState.fullName,
      email: formState.email,
      howYouFoundUs: formState.howYouFoundUs,
    };

    fetch("https://nnfek1uak2.execute-api.us-east-1.amazonaws.com/dev/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsSubmittedSuccessfully(true); // Show success message
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSubmitting(false); // End loading
      });
  };

  const renderForm = () => {
    return (
      <>
        <FormWrapper>
          <StyledTextField
            fullWidth
            name="fullName"
            onChange={handleChange}
            variant="outlined"
            label="Full Name"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.fullName}
            error={!!formState.errors.fullName}
          />

          <StyledTextField
            fullWidth
            name="email"
            onChange={handleChange}
            variant="outlined"
            label="Email"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.email}
            error={!!formState.errors.email}
          />
          <StyledTextField
            fullWidth
            multiline
            name="howYouFoundUs"
            onChange={handleChange}
            rows={4}
            variant="outlined"
            label="How did you find out about us?"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.howYouFoundUs}
            error={!!formState.errors.howYouFoundUs}
          />
        </FormWrapper>
        <Button
          disabled={!isFormValid()}
          onClick={isFormValid() ? sendMessage : null}
          text="Send"
          arrow="right"
        />
      </>
    );
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress
          color="inherit"
          sx={{ color: "white", flexGrow: "0" }}
        />
      ) : !isSubmittedSuccessfully ? (
        renderForm()
      ) : (
        <Body1 textAlign="center">
          Thank you for registering! We will be in touch shortly.
        </Body1>
      )}
    </>
  );
}
