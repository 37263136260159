import React from "react";
import "style/index.css";

// components
import { Heading4 } from "components/TextStyles";
import {
  TopTitle,
  TopWrapper,
  Gradient,
  TopImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/publicsector/publicSector1.png";

export default function Top() {
  return (
    <>
      <TopImage image={image} />
      <Gradient />
      <TopWrapper className="container">
        <TopTitle>Public Sector</TopTitle>
        <Heading4>
          As the global population ages, this demographic shift represents not
          just a challenge, but a significant opportunity for governments
          worldwide. It's a time for new social and corporate policies and
          approaches.
        </Heading4>
      </TopWrapper>
    </>
  );
}
