import * as React from "react";

function SvgComponent(props) {
  const width = props.width || "200";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 200 200"
      fill="none"
    >
      <path
        d="M109.375 77.3674L164.081 22.6609L177.34 35.9192L122.634 90.625H200V109.375H109.375V128.125C109.375 157.465 133.16 181.25 162.5 181.25H187.5V200H162.5C122.805 200 90.625 167.82 90.625 128.125V109.375H0V90.625H77.3661L22.6602 35.9191L35.9184 22.6608L90.625 77.3674L90.625 0H109.375V77.3674Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
