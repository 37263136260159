import React from "react";
import "style/index.css";

// components
import { Heading4 } from "components/TextStyles";
import {
  TopTitle,
  TopWrapper,
  Gradient,
  TopImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import BackgroundImage from "assets/img/organisations/individuals/individuals1.png";

export default function Top() {
  return (
    <>
      <TopImage image={BackgroundImage} />
      <Gradient />
      <TopWrapper className="container">
        <TopTitle>Individuals</TopTitle>
        <Heading4>
          Whether you are thinking about retiring, retired, or never going to
          retire, join us to confidently explore your passions and make the most
          of your next chapter.
        </Heading4>
      </TopWrapper>
    </>
  );
}
