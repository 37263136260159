import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="clamp(100px, 18vw, 400px)"
      height="clamp(100px, 5.5vw, 400px)"
      viewBox="0 0 177 51"
      fill="none"
    >
      <path
        d="M65.6561 1.38623C47.8344 1.38623 20.7567 4.90528 9.30568 20.4459C4.35646 27.1627 -3.98608 42.7507 6.72756 47.7924C22.6888 55.3036 51.7286 37.9139 61.6047 26.0625C64.4219 22.682 72.8353 16.7288 73.8508 12.1591C75.0053 6.96407 61.1466 10.5952 59.0266 10.9621C50.2791 12.4761 29.6075 18.3404 40.7956 31.1267C48.93 40.4232 70.2552 33.9325 78.8229 28.6407C84.8047 24.946 97.1943 9.62475 97.146 9.67305C81.6936 25.1255 118.919 37.8182 126.794 24.0369C127.068 23.5583 175.042 40.9778 175.042 19.6172"
        stroke="#A6BCFF"
        strokeWidth="2.34384"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgComponent;
