import * as React from "react";

function SvgComponent(props) {
  const strokeColor = props.stroke || "white";
  const paddingTop = props.paddingTop || "0px";
  const width = props.width || "32";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 32 32"
      fill="none"
      style={{ paddingTop }}
    >
      <path
        d="M5 16H27"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7L27 16L18 25"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
