import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Top from "screens/contact/sections/Top";
import JoinBeta from "../../components/common/RegisterNow";
import Footer from "components/common/Footer";

export default function Contact() {
  return (
    <>
      <TopNavbar />
      <Top />
      <JoinBeta />
      <Footer />
    </>
  );
}
