import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  SignUpWrapper,
  SignUpLeftSide,
  SignUpRightSide,
} from "screens/organisations/OrganisationStyledSections";
import CorporateManifestoSignUp from "components/forms/manifesto/CorporateManifestoSignUp";

export default function SignUp() {
  return (
    <>
      <SignUpWrapper className="container">
        <SignUpLeftSide>
          <Heading3>
            Interested in becoming an age inclusive
            <span style={{ color: "var(--Lavender, #A6BCFF)" }}> Employer</span>
            ?
          </Heading3>
          <Body1>
            Sign our manifesto to demonstrate your commitment to age inclusivity
            and the longevity economy.
          </Body1>
        </SignUpLeftSide>
        <SignUpRightSide>
          <CorporateManifestoSignUp />
        </SignUpRightSide>
      </SignUpWrapper>
    </>
  );
}
