import React from "react";
import styled from "styled-components";

// asset
import image1 from "assets/img/articleheaderimages/media2.jpg";
import image2 from "assets/img/mediaimages/media3.jpeg";
// import image2 from "assets/img/articleheaderimages/catherine-moran-image-mind-wandering.jpg";
import image3 from "assets/img/articleheaderimages/Guardian-article-image.jpg";
import image4 from "assets/img/articleheaderimages/honeygood.jpg";
import image5 from "assets/img/articleheaderimages/due-best-business.png";
import image6 from "assets/img/articleheaderimages/longevity-dividend.jpg";

// components
import { Grid } from "@mui/material";
import { Heading4 } from "components/TextStyles";
import { ArticlesContainer } from "components/styledsections/ResourcesStyledSections";

const Articles = [
  {
    image: image1,
    isPopular: true,
    category: "Age-Inclusive",
    date: "October 10, 2024",
    title:
      "Grey Matters and AgeInc announce a partnership to combat age discrimination",
    subtitle:
      "Grey Matters, who help over 45s shape their lives by providing personalised life plans for longevity and ageing well, and AgeInc, the Age Inclusive Employer Endorsement program, proudly announce a new strategic partnership.",
    articleURL:
      "https://getthewordout.com.au/press-release/press-release-in-recognition-of-ageism-awareness-day-grey-matters-and-ageinc-announce-a-partnership-to-combat-age-discrimination-in-the-workplace/",
  },
  {
    image: image2,
    isPopular: false,
    category: "Longevity",
    date: "February 8, 2024",
    title:
      "Grey Matters: New venture led by ex-Publicis Sapient MD aims to navigate the challenges of an ageing population",
    subtitle:
      "Four of the country's top innovation, technology, creative and commercial executives have joined forces on the business.",
    articleURL:
      "https://getthewordout.com.au/press-release/press-release-in-recognition-of-ageism-awareness-day-grey-matters-and-ageinc-announce-a-partnership-to-combat-age-discrimination-in-the-workplace/",
  },
];

export default function Media() {
  return (
    <>
      <Wrapper className="container">
        <VerticalSectionWrapper>
          <Grid
            container
            bgcolor="transparent"
            spacing={4}
            justifyContent="flex-start"
            width="auto"
          >
            {Articles.map((article, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <ArticlesContainer
                  image={article.image}
                  isPopular={article.isPopular}
                  category={article.category}
                  date={article.date}
                  title={article.title}
                  subtitle={article.subtitle}
                  articleURL={article.articleURL}
                />
              </Grid>
            ))}
          </Grid>
        </VerticalSectionWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
`;

const VerticalSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-top: 85px;
  padding-bottom: 65px;
`;
