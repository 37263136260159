import styled from "styled-components";
import React from "react";

// Components
import { Heading2 } from "components/TextStyles";
import Button from "components/styledelements/Button";

// Assets
import MaskLogo from "assets/svg/gmlogos/GMLogoBlack";

export default function DayLife() {
  return (
    <Wrapper
      id="dayLife"
      className="container flexDayCenter backgroundLavender"
    >
      <MaskLogo />
      <Heading2
        maxFontSize="4rem"
        fontColor="var(--Black, #000)"
        textAlign="center"
        lineHeight="120%"
      >
        Join us for Early Beta Access
      </Heading2>

      <Footer>
        <Button to="/sign-in" color="black" text="Join" arrow="right" />
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  gap: 12px;
  padding-top: 60px;
  padding-bottom: 30px;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
    padding-bottom: 0;
  }
`;

const Footer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 64px;
  margin: 30px 0 30px 0;

  @media (max-width: 960px) {
    flex-direction: column;
    gap: 32px;
    margin-bottom: 32px;
  }
`;
