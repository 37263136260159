import * as React from "react";

function SvgComponent(props) {
  const { stroke = "white" } = props;
  const width = props.width || "24px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.8907 11.8623V15.4024C11.8907 19.4578 15.1783 22.7454 19.2338 22.7454H22.2952"
        stroke={stroke}
        strokeWidth="2.20292"
      />
      <path d="M0 11.8623H23.7816" stroke={stroke} strokeWidth="2.20292" />
      <path
        d="M11.8909 0.25415L11.8909 11.8628"
        stroke={stroke}
        strokeWidth="2.20292"
      />
      <path
        d="M20.2993 3.58228L12.1244 11.5632"
        stroke={stroke}
        strokeWidth="2.20323"
      />
      <path
        d="M12.4011 12.2886L3.48302 3.58207"
        stroke={stroke}
        strokeWidth="2.20323"
      />
    </svg>
  );
}

export default SvgComponent;
