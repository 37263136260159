import React from "react";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  LeftSide,
  RightSide,
  UpperWrapper,
  UpperImage,
} from "screens/organisations/OrganisationStyledSections";

// assets
import image from "assets/img/organisations/individuals/individuals2.png";

export default function UpperTextSection() {
  return (
    <>
      <UpperWrapper className="container">
        <LeftSide>
          <Heading3>Navigate what's next</Heading3>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Our experiential platform helps you cultivate your purpose, pursue
            your passions, learn new tricks, tick things off your bucket list
            and allows you to work when you want, how you want, doing what you
            want!
          </Body1>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            This is the most exciting time of your life, get curious, get
            connected and get creative! Get access to age inclusive employers
            and a community who value your experience.
          </Body1>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            The Grey Matters community believes age is an asset and life is a
            gift. Happiness, passion and fulfillment have no age limit.
          </Body1>
        </LeftSide>
        <RightSide>
          <UpperImage src={image} />
        </RightSide>
      </UpperWrapper>
    </>
  );
}
