import React, { useState } from "react";
import "style/index.css";

// components
import {
  StyledTextField,
  StyledSelect,
  StyledFormControl,
  StyledMenuProps,
  StyledInputLabel,
  Row,
} from "components/StyledMuiElements";
import { Body1 } from "components/TextStyles";
import { CircularProgress, MenuItem } from "@mui/material";
import { FormWrapper } from "screens/organisations/OrganisationStyledSections";
import Button from "components/styledelements/Button";

export default function SignUp() {
  const initialState = {
    fullName: "",
    jobTitle: "",
    clubName: "",
    numberOfMembers: "",
    phoneNumber: "",
    email: "",
    clubLogo: "",
    errors: {},
  };

  const [formState, setFormState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormState((prevState) => ({
      ...prevState,
      [name]: newValue,
      errors: {
        ...prevState.errors,
        [name]: validateField(name, newValue),
      },
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "fullName":
      case "jobTitle":
      case "clubName":
        return value.length > 0 ? "" : "This field is required.";
      case "phoneNumber":
        return /^(\d{10}|\+\d{11})$/.test(value) ? "" : "Invalid phone number.";
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
          ? ""
          : "Invalid email format.";
      default:
        return "";
    }
  };

  const isFormValid = () => {
    const areFieldsFilled =
      formState.fullName &&
      formState.jobTitle &&
      formState.clubName &&
      formState.numberOfMembers &&
      formState.phoneNumber &&
      formState.email;
    const areNoErrors = Object.values(formState.errors).every(
      (error) => error === ""
    );
    return areFieldsFilled && areNoErrors;
  };

  const sendMessage = () => {
    setIsSubmitting(true);

    const formData = {
      type: "Clubs",
      manifesto: true,
      name: formState.fullName,
      jobTitle: formState.jobTitle,
      clubName: formState.clubName,
      numberOfMembers: formState.numberOfMembers,
      phoneNumber: formState.phoneNumber,
      email: formState.email,
      clubLogo: formState.clubLogo,
    };

    fetch("https://nnfek1uak2.execute-api.us-east-1.amazonaws.com/dev/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsSubmittedSuccessfully(true); // Show success message
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSubmitting(false); // End loading
      });
  };

  const renderForm = () => {
    return (
      <>
        <FormWrapper>
          <StyledTextField
            fullWidth
            name="fullName"
            onChange={handleChange}
            variant="outlined"
            label="Full Name"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.fullName}
            error={!!formState.errors.fullName}
          />

          <Row>
            <StyledTextField
              fullWidth
              name="jobTitle"
              onChange={handleChange}
              variant="outlined"
              label="Your Job Title"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.jobTitle}
              error={!!formState.errors.jobTitle}
            />
            <StyledTextField
              fullWidth
              name="clubName"
              onChange={handleChange}
              variant="outlined"
              label="Club Name"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.clubName}
              error={!!formState.errors.clubName}
            />
          </Row>
          <Row>
            <StyledFormControl fullWidth variant="outlined">
              <StyledInputLabel htmlFor="number-of-members">
                Number of Members
              </StyledInputLabel>
              <StyledSelect
                id="number-of-members"
                name="numberOfMembers"
                value={formState.numberOfMembers}
                onChange={handleChange}
                label="Number of Members>"
                MenuProps={StyledMenuProps}
              >
                <MenuItem value="5 - 15">5 - 15</MenuItem>
                <MenuItem value="16 - 30">16 - 30</MenuItem>
                <MenuItem value="31 - 50">31 - 50</MenuItem>
                <MenuItem value="50+">50+</MenuItem>
              </StyledSelect>
            </StyledFormControl>
            <StyledTextField
              fullWidth
              name="phoneNumber"
              onChange={handleChange}
              variant="outlined"
              label="Phone Number"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.phoneNumber}
              error={!!formState.errors.phoneNumber}
            />
          </Row>
          <StyledTextField
            fullWidth
            name="email"
            onChange={handleChange}
            variant="outlined"
            label="Work Email"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.email}
            error={!!formState.errors.email}
          />
          <StyledTextField
            fullWidth
            multiline
            name="clubLogo"
            onChange={handleChange}
            variant="outlined"
            label="Your Club Logo's URL"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.clubLogo}
            error={!!formState.errors.clubLogo}
          />
        </FormWrapper>
        <Button
          disabled={!isFormValid()}
          onClick={isFormValid() ? sendMessage : null}
          text="Sign"
          arrow="right"
        />
      </>
    );
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress
          color="inherit"
          sx={{ color: "white", flexGrow: "0" }}
        />
      ) : !isSubmittedSuccessfully ? (
        renderForm()
      ) : (
        <Body1 textAlign="center">Thank you for signing our Manifesto!</Body1>
      )}
    </>
  );
}
