import React from "react";
import "style/index.css";

// components
import { Body1, Heading5 } from "components/TextStyles";
import {
  LowerWrapper,
  Tile,
  BackgroundWoman,
} from "screens/organisations/OrganisationStyledSections";

// assets
import GreyMattersEmblem from "assets/svg/gmlogos/GMLogoWhite";
import BackgroundWomanImg from "assets/img/organisations/backgroundWoman.png";

export default function LowerTextSection() {
  return (
    <>
      <LowerWrapper className="container">
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Attract the over 45s
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            By joining Grey Matters, your club opens its doors to a community of
            individuals over 45 who bring a wealth of experience. Enhancing the
            diversity of your club.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">Hire the over 45s</Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Utilise Greytasker to tap into the expertise of the over-45 talent
            pool. By doing so, you can benefit from their exceptional
            flexibility, skills, reliability, and strong work ethic. Embrace
            this opportunity to become an age inclusive organization.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Retrain your over 45s
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Grey Matters champions the concept of valuing ageing as an asset,
            emphasising lifelong learning. The wide range of courses offered not
            only enriches individuals by improving their skills, but also boosts
            the vibrancy of your club.
          </Body1>
        </Tile>
        <BackgroundWoman src={BackgroundWomanImg} />
      </LowerWrapper>
    </>
  );
}
