import React from "react";

// Sections
import TopNavbar from "components/nav/TopNavbar";
import Footer from "components/common/Footer";
import Top from "./sections/Top";
import UpperTextSection from "./sections/UpperTextSection";
import LowerTextSection from "./sections/LowerTextSection";
import SignUp from "./sections/SignUp";

export default function Clubs() {
  return (
    <>
      <TopNavbar />
      <Top />
      <UpperTextSection />
      <LowerTextSection />
      <SignUp />
      <Footer />
    </>
  );
}
