
import * as React from "react";

function SvgComponent(props) {
  const { margin } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1146 2" fill="none" style={{ margin }}>
      <path opacity="0.4" d="M0 1H1146" stroke="white" />
    </svg>
  );
}

export default SvgComponent;


