import React, { useState } from "react";
import styled from "styled-components";

// components
import { Checkbox } from "@mui/material";
import { Body1, Body2, Heading2 } from "components/TextStyles";

// assets
import UncheckedIcon from "../../../assets/svg/icons/quiz/UncheckedHAL";
import CheckedIcon from "../../../assets/svg/icons/quiz/CheckedHAL";
import Button from "components/styledelements/Button";

const options = [
  "Your health",
  "Exercising",
  "Continuing to work full time but changing professions/vocations",
  "Connecting with family and friends",
  "Continuing or learning new hobbies",
  "Improving your financial stability",
  "Meeting new friends and/or partners",
  "Spending time outdoors in nature",
  "Creative endeavours such as arts, music",
  "Joining new clubs",
  "Travelling and exploring",
  "Helping the younger generations by sharing your wisdom/experience",
  "Time with animals/pets",
  "Learning new things",
  "Volunteering/Giving back",
  "Having the freedom to do part-time jobs, you when you want to",
];

export default function HAL({ onSubmit }) {
  const [selectedCards, setSelectedCards] = useState([]);

  const toggleOption = (option) => {
    setSelectedCards((prev) => {
      if (prev.includes(option)) {
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleSubmit = () => {
    onSubmit(selectedCards);
  };

  return (
    <>
      <Wrapper>
        <UpperWrapper>
          <Heading2>
            What makes you
            <span style={{ color: "var(--Aqua, #4BFFDF)" }}> Happy</span>?
          </Heading2>
          <Body1>
            Select 3 or more areas, that make you truly happy and that you'd
            like to focus your time on going forward
          </Body1>
        </UpperWrapper>
        <BoxWrapper>
          {options.map((option) => (
            <Card
              key={option}
              selected={selectedCards.includes(option)}
              onClick={() => toggleOption(option)}
            >
              <Body2 fontColor="black">{option}</Body2>
              <CheckboxWrapper>
                <Checkbox
                  disableRipple
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  checked={selectedCards.includes(option)}
                />
              </CheckboxWrapper>
            </Card>
          ))}
        </BoxWrapper>
        <Button
          onClick={selectedCards.length < 3 ? null : handleSubmit}
          text="Submit"
          arrow="right"
          disabled={selectedCards.length < 3}
        />
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 96px;
  gap: 48px;

  @media (max-width: 860px) {
    padding: 48px;
  }
`;

const UpperWrapper = styled.section`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 860px) {
    width: 100%;
  }
`;

const BoxWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 20px;
  column-gap: 40px;
  background: transparent;

  @media (max-width: 860px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  position: relative;
  padding: 15px 15px 60px 15px;
  background: ${(props) =>
    props.selected ? "rgb(91, 101, 185)" : "var(--Lavender, #a6bcff)"};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const CheckboxWrapper = styled.section`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
