import React from "react";
import styled from "styled-components";
import "style/index.css";

// components
import { Body1, Heading3 } from "components/TextStyles";
import {
  LeftSide,
  RightSide,
  UpperWrapper,
} from "screens/organisations/OrganisationStyledSections";

// assets
import GreyTaskerImg2 from "assets/img/greytasker/GreyTasker2.png";

export default function UpperTextSection() {
  return (
    <>
      <UpperWrapper className="container">
        <LeftSide>
          <Heading3 lineHeight="120%">
            There is so much more to work than just our day jobs
          </Heading3>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Life is busy. It’s not just the hustle and bustle of the 9 to 5.
            There’s rarely enough hours in the day to do everything. There’s
            kids, there’s parents, there’s pets and let’s not forget ourselves.
            We understand this and that’s why we created GreyTasker.
          </Body1>
        </LeftSide>
        <RightSide>
          <GroupImg />
        </RightSide>
      </UpperWrapper>
    </>
  );
}

const GroupImg = styled.img.attrs(() => ({ src: GreyTaskerImg2 }))`
  border-radius: 16px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  /* DS */
  box-shadow: 53px 67px 106px 0px rgba(0, 0, 0, 0.55);

  width: 25vw;

  @media (max-width: 760px) {
    width: 45vw;
  }

  @media (max-width: 560px) {
    width: 65vw;
  }

  @media (min-width: 1800px) {
    width: 20vw;
  }

  @media (min-width: 2200px) {
    width: 15vw;
  }
`;
