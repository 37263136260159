import React from "react";
import "style/index.css";

// components
import {
  ExperienceWrapper,
  ExperienceBackgroundImage,
  ExperienceHeaderWrapper,
  ExperienceTilesWrapper,
  ExperienceTile,
  // ExperienceArrowWrapper,
  ExperienceIconWrapper,
} from "components/styledsections/ExperienceStyledSections";
import { Heading3, Heading5, Body1, Body2 } from "components/TextStyles";
// import Link from "components/Elements/ScrollToTopLink";

// assets
import LearnBulb from "assets/svg/icons/experience/LearnBulb";
import HealthHeart from "assets/svg/icons/experience/HealthHeart";
import ExploreCompass from "assets/svg/icons/experience/ExploreCompass";
import SocialSunny from "assets/svg/icons/experience/SocialSunny";
import WorkConstruct from "assets/svg/icons/experience/WorkConstruct";
import WealthKey from "assets/svg/icons/experience/WealthKey";
// import ArrowRight from "assets/svg/ArrowRight";
import Background from "assets/img/backgrounds/dandelionField.png";

export default function Services() {
  return (
    <>
      <ExperienceWrapper>
        <ExperienceHeaderWrapper marginTop="100px">
          <Heading3>Services We Offer</Heading3>
          <Body1 fontColor="var(--Lite-Grey, #CCC);" textAlign="center">
            Retirement is one of the most highly sought-after phases of life but
            can also be one of the most challenging and least understood. Help
            your over-45 employees reframe their narrative and purpose, with
            their own personal, adaptive, and experiential transition plan,
            guided by ELLA our friendly and intelligent AI assistant. Depending
            on the modules you select we will make your employee transition
            easy, engaging, psychological safe and timebound. Let us help your
            team embrace their midlife new chapter with confidence.
          </Body1>
        </ExperienceHeaderWrapper>
        <ExperienceTilesWrapper>
          <ExperienceTile borderColor="var(--Tea-Green, #C2E9C2);">
            <ExperienceIconWrapper>
              <LearnBulb color="#C2E9C2" />
            </ExperienceIconWrapper>
            <Heading5
              fontColor="var(--Tea-Green, #C2E9C2);"
              maxFontSize="2.1rem"
            >
              Learn
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Help your employees become lifelong learners; keeping skills
              update, learning new skills, and gaining micro-creditials to help
              them embrace post-corporate life. Access to diverse courses not
              only to help their transition from full time employment but to
              teach valuable tech, creative, vocational and life skills.
              Customise with your company logo and add your own propriety
              courses.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Tea-Green, #C2E9C2)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Cream, #F0D8A7)">
            <ExperienceIconWrapper>
              <WorkConstruct color="#F0D8A7" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Cream, #F0D8A7)" maxFontSize="2.1rem">
              Work
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Our Work Module facilitates seamless transitions from traditional
              employment to more flexible arrangements. The needs of both
              organisations and individuals are considered at Greytasker. We
              provide access to a spectrum of opportunities, including part-time
              work, temporary assignments, just-in-time gigs, and volunteering
              options.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Cream, #F0D8A7)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Aqua, #4BFFDF)">
            <ExperienceIconWrapper>
              <HealthHeart color="#4BFFDF" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Aqua, #4BFFDF)" maxFontSize="2.1rem">
              Wealth
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Support your employees in making informed financial, legal, and
              insurance decisions. Our comprehensive assistance is tailored to
              guide them through the complexities of longevity financial and
              insurance planning and coverage. Empower your employees to make
              sound financial decisions, giving a great sense of security and
              enabling them to focus on their next chapter.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Aqua, #4BFFDF)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Silver, #999999)">
            <ExperienceIconWrapper>
              <WealthKey color="#999999" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Silver, #999999)" maxFontSize="2.1rem">
              Health
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Our Health Module is dedicated to empowering your employees to
              proactively manage one of their most precious assets their health.
              Poor health can impact their income, their family, their
              lifestyle, and their longevity. Combining precision medicine and
              lifestyle coaching which aspire to help your employee’s life a
              longer healthier life.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Silver, #999999)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Light-Cream, #FEF4ED);">
            <ExperienceIconWrapper>
              <ExploreCompass color="#FEF4ED" />
            </ExperienceIconWrapper>
            <Heading5
              fontColor="var(--Light-Cream, #FEF4ED);"
              maxFontSize="2.1rem"
            >
              Explore
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              We help your employees broaden their horizons and create memorable
              life experiences. It’s important to rebalance work and leisure.
              We’ll guide them through a journey of personal enrichment and
              discovery at their own pace. Their well-deserved adventure begins
              with us, laying an important foundation for their embracing and
              enjoying their new chapter.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Light-Cream, #FEF4ED)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
          <ExperienceTile borderColor="var(--Lavender, #A6BCFF)">
            <ExperienceIconWrapper>
              <SocialSunny color="#A6BCFF" />
            </ExperienceIconWrapper>
            <Heading5 fontColor="var(--Lavender, #A6BCFF)" maxFontSize="2.1rem">
              Social
            </Heading5>
            <Body2 fontColor="var(--Lite-Grey, #CCC);">
              Retaining a sense of connection during and post corporate life is
              imperative. Cultivate a sense of community whilst still at work.
              Allow your employees to join or create clubs, fostering social
              circles for employees to share opportunities and navigate
              challenges together. Strengthen both personal and professional
              connections within and outside your organisation.
            </Body2>
            {/* <Link to="/">
              <ExperienceArrowWrapper>
                <ArrowRight width="20px" stroke="var(--Lavender, #A6BCFF)" />
              </ExperienceArrowWrapper>
            </Link> */}
          </ExperienceTile>
        </ExperienceTilesWrapper>
        <ExperienceBackgroundImage backgroundImage={Background} />
      </ExperienceWrapper>
    </>
  );
}
