import styled from "styled-components";
import React, { useState, useRef } from "react";

// Components
import { Body1, Heading3, Heading5 } from "components/TextStyles";

// Assets
import DownChevron from "assets/svg/arrows/DownChevron";
import Line from "assets/svg/Line";

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  const answerRef = useRef(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  return (
    <FAQContainer onClick={handleToggle}>
      <FAQHeader>
        <Heading5 fontColor="var(--White, #FFF)">{question}</Heading5>
        <div
          style={{ display: "flex", alignItems: "center", paddingTop: "2px" }}
        >
          <DownChevronStyled />
        </div>
      </FAQHeader>
      <FAQAnswer
        style={{
          height: isOpen ? `${answerRef.current.scrollHeight}px` : "0",
          margin: isOpen ? "8px 0" : "0",
        }}
        ref={answerRef}
      >
        <Body1 dangerouslySetInnerHTML={createMarkup(answer)} />
      </FAQAnswer>
    </FAQContainer>
  );
}

export default function FAQ() {
  return (
    <Wrapper id="faq" className="container backgroundLighter">
      <Heading3 marginBottom="20px">FAQs</Heading3>
      <FAQItem
        key="howDoesItWork"
        question="How does Grey Matters work?"
        answer="Grey Matters uses the latest AI technology to take the stress and uncertainty out of transitioning into midlife."
      />
      <Line margin="8px 0 4px 0" />
      <FAQItem
        key="howJoin"
        question="Who can join Grey Matters?"
        answer="Anyone can join Grey Matters but our community and platform is tailored to help the over-45s community to transition into midlife and make the most of their life longevity."
      />
      <Line margin="8px 0 4px 0" />
      <FAQItem
        key="howBecomePartner"
        question="How do I become a Grey Matters partner?"
        answer="Please <a style='color: white; text-decoration: underline !important;' href='/contact'>contact us</a>. We look forward to partnering with you!"
      />
      <Line margin="8px 0 4px 0" />
      <FAQItem
        key="pricing"
        question="Pricing and Payments"
        answer="For corporates and clubs, our solution is customisable to suit your organisation's size. Chat to us about your specific needs.  For individuals, please click Register in the Contact Us page for more details."
      />
      <Line margin="8px 0 4px 0" />
      <FAQItem
        key="privacy"
        question="Privacy Policy"
        answer="Our policy can be viewed <a style='color: white; text-decoration: underline !important;' href='/privacy'>here</a>"
      />
      <Line margin="8px 0 4px 0" />
      <FAQItem
        key="ethicalAI"
        question="Ethical Use of AI"
        answer="Our policy can be viewed <a style='color: white; text-decoration: underline !important;' href='/privacy'>here</a>"
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  padding-top: clamp(40px, calc(40px + 4vw), 80px);
  padding-bottom: clamp(40px, calc(40px + 4vw), 80px);
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const FAQContainer = styled.section`
  width: 100%;
  padding: 5px 0;
  overflow: hidden;
`;

const FAQHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  cursor: pointer;
  padding: 0 5px;
`;

const FAQAnswer = styled.div`
  padding: 0 5px 0;
  max-width: 95%;
  transition: height 0.3s ease-in-out, margin 0.3s ease-in-out;
  opacity: 0.45;
`;

const DownChevronStyled = styled(DownChevron)`
  @media (max-width: 960px) {
    width: 24px;
    height: 24px;
  }
  padding-right: 5px;
`;
