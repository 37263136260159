import React from "react";
import "style/index.css";

// components
import {
  LowerWrapper,
  Tile,
  BackgroundWoman,
} from "screens/organisations/OrganisationStyledSections";
import { Heading5, Body1 } from "components/TextStyles";

// assets
import GreyMattersEmblem from "assets/svg/gmlogos/GMLogoWhite";
import BackgroundWomanImg from "assets/img/organisations/backgroundWoman.png";

export default function LowerTextSection() {
  return (
    <>
      <LowerWrapper className="container">
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Personalised age transition plan
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Help your midlife employees transition confidently into post
            corporate life, whilst still benefiting from their expertise on a
            gig basis. Our experiential platform offers employees a personalised
            journey of growth, opportunity, health and connection.
          </Body1>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            Your employees will be your best cheerleaders, advocates, alumni and
            potential customers.
          </Body1>
        </Tile>
        <Tile>
          <GreyMattersEmblem width="30" />
          <Heading5 fontColor="var(--White, #FFF);">
            Become an age inclusive employer
          </Heading5>
          <Body1 fontColor="var(--Lite-Grey, #CCC);">
            In a world where the working population is shrinking and people are
            living longer, organisations will need to retain and attract the
            over 45s. There are many benefits to adopting an age inclusive
            platform, notably including enhanced productivity, improved problem
            solving, lower regrettable turnover rates, reduced long service
            leave and heightened employee satisfaction.
          </Body1>
        </Tile>
        <BackgroundWoman src={BackgroundWomanImg} />
      </LowerWrapper>
    </>
  );
}
