import React, { useState } from "react";
import "style/index.css";

// components
import { StyledTextField, Row } from "components/StyledMuiElements";
import { Body1 } from "components/TextStyles";
import { CircularProgress } from "@mui/material";
import { FormWrapper } from "screens/organisations/OrganisationStyledSections";
import Button from "components/styledelements/Button";

export default function SignUp() {
  const initialState = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    location: "",
    errors: {},
  };

  const [formState, setFormState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: validateField(name, value),
      },
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return value.length > 0 ? "" : "This field is required.";
      case "phoneNumber":
        return /^(\d{10}|\+\d{11})$/.test(value) ? "" : "Invalid phone number.";
      case "email":
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(value).toLowerCase()
        )
          ? ""
          : "Invalid email format.";
      case "location":
        return value.length >= 5 && value.length <= 10000
          ? ""
          : "Please enter a valid location";
      default:
        return "";
    }
  };

  const isFormValid = () => {
    const areFieldsFilled =
      formState.firstName &&
      formState.lastName &&
      formState.phoneNumber &&
      formState.email &&
      formState.location;
    const areNoErrors = Object.values(formState.errors).every(
      (error) => error === ""
    );
    return areFieldsFilled && areNoErrors;
  };

  const sendMessage = () => {
    setIsSubmitting(true); // Start loading

    const formData = {
      type: "Individuals",
      name: `${formState.firstName} ${formState.lastName}`,
      phoneNumber: formState.phoneNumber,
      email: formState.email,
      location: formState.location,
    };

    fetch("https://nnfek1uak2.execute-api.us-east-1.amazonaws.com/dev/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsSubmittedSuccessfully(true); // Show success message
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSubmitting(false); // End loading
      });
  };

  const renderForm = () => {
    return (
      <>
        <FormWrapper>
          <Row>
            <StyledTextField
              fullWidth
              name="firstName"
              onChange={handleChange}
              variant="outlined"
              label="First Name"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.firstName}
              error={!!formState.errors.firstName}
            />
            <StyledTextField
              fullWidth
              name="lastName"
              onChange={handleChange}
              variant="outlined"
              label="Last Name"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{ style: { color: "white" } }}
              helperText={formState.errors.lastName}
              error={!!formState.errors.lastName}
            />
          </Row>
          <StyledTextField
            fullWidth
            name="phoneNumber"
            onChange={handleChange}
            variant="outlined"
            label="Phone Number"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.phoneNumber}
            error={!!formState.errors.phoneNumber}
          />
          <StyledTextField
            fullWidth
            name="email"
            onChange={handleChange}
            variant="outlined"
            label="Email"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.email}
            error={!!formState.errors.email}
          />
          <StyledTextField
            fullWidth
            name="location"
            onChange={handleChange}
            variant="outlined"
            label="Location"
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{ style: { color: "white" } }}
            helperText={formState.errors.location}
            error={!!formState.errors.location}
          />
        </FormWrapper>
        <Button
          disabled={!isFormValid()}
          onClick={isFormValid() ? sendMessage : null}
          text="Send"
          arrow="right"
        />
      </>
    );
  };

  return (
    <>
      {isSubmitting ? (
        <CircularProgress
          color="inherit"
          sx={{ color: "white", flexGrow: "0" }}
        />
      ) : !isSubmittedSuccessfully ? (
        renderForm()
      ) : (
        <Body1 textAlign="center">
          Thank you for signing up! You will receive an email shortly.
        </Body1>
      )}
    </>
  );
}
