import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="82"
      height="82"
      viewBox="0 0 82 82"
      fill="none"
    >
      <path
        d="M41.0002 40.0252V52.2315C41.0002 66.2149 52.3359 77.5506 66.3192 77.5506H76.875"
        stroke="#12141A"
        strokeWidth="7.59572"
      />
      <path d="M0 40.0252H81.9996" stroke="#12141A" strokeWidth="7.59572" />
      <path
        d="M41.001 0L41.001 40.027"
        stroke="#12141A"
        strokeWidth="7.59572"
      />
      <path
        d="M69.9927 11.4749L41.8053 38.9935"
        stroke="#12141A"
        strokeWidth="7.59682"
      />
      <path
        d="M42.7603 41.495L12.0104 11.4747"
        stroke="#12141A"
        strokeWidth="7.59682"
      />
    </svg>
  );
}

export default SvgComponent;
