import React, { useState, useEffect } from "react";
import { Amplify, Auth } from "aws-amplify";
import config from "./config/auth.json";

/**
 * Decodes the JWT payload, checks the expiration date, and returns true if it's expired.
 * This version is intended for use in browser environments and does not verify the token's signature.
 * 
 * @param token - The JWT to be decoded and checked for expiration.
 * @returns True if the token is expired, false otherwise.
 */
export function isJwtExpired(token) {
  try {
    // Split the token into parts and decode the payload part from Base64URL
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    // Parse the payload as JSON and extract the exp field
    const payload = JSON.parse(jsonPayload);
    const expirationDate = payload.exp;

    // Get the current time in seconds since the epoch
    const now = Math.floor(Date.now() / 1000);

    // Check if the current time is greater than the expiration date
    return now > expirationDate;
  } catch (error) {
    console.error('Error decoding the token:', error);
    return false;
  }
}

export function GMAmplifyInit() {
  const [isAmplifyConfigured, setIsAmplifyConfigured] = useState(false);

  useEffect(() => {
    if (!isAmplifyConfigured) {
      Amplify.configure({
        aws_appsync_graphqlEndpoint: config.graphQLEndpoint,
        aws_appsync_region: config.region,
        aws_appsync_authenticationType: "AWS_LAMBDA",
      });

      setIsAmplifyConfigured(true);
    }
  }, [isAmplifyConfigured]);

  return <></>;
}

export function setJWT(jwt) {
  localStorage.setItem("jwt", jwt);
}

export function getJWT() {
  return localStorage.getItem("jwt");
}
